import React, { useContext } from "react";
import TenantContext from "./../OnlineFormContext";

function Header(props) {
  const contextValues = useContext(TenantContext);
  return (
    <div className="sub-header">
      <h3 className="sm-title">Request Service</h3>
      <span className="bs-logo">
        <img src={contextValues.tenantLogo} alt="Logo" />
      </span>
    </div>
  );
}

export default Header;
