import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Header from "./../Components/Header.js";
import {
  PrimaryButton,
  BorderInheritButton,
  HiddenPrimaryButton,
  HiddenBorderInheritButton,
} from "./../Components/Button.js";
import TenantContext from "./../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";

function EnterFleetNo({ startOver, fleetSelected, customerAccountUnknown }) {
  const contextValues = useContext(TenantContext);
  const [message, setMessage] = useState(null);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  const crosswalkTenantInfo = (tenant) => (
    {
      name: tenant.name,
      crosswalkEntryId: tenant.crosswalk_entry_id,
      addresses: [
        {
          address: tenant.address,
        }
      ]
    }
  )

  return (
    <Formik
      initialValues={{ fleet_no: "" }}
      onSubmit={async (values) => {
        let result = await reachAPI.fetchFleetInformation(values.fleet_no);
        if (result.message) {
          setMessage(result.message);
        } else if (result.tenant.crosswalk_entry_id) {
          setMessage(null);
          fleetSelected({
            fleetInfo: crosswalkTenantInfo(result.tenant),
            fleetNo: result.account_no,
          });
        } else {
          setMessage(null);
          fleetSelected({
            fleetInfo: result.tenant,
            fleetNo: result.account_no,
          });
        }
      }}
      validationSchema={Yup.object().shape({
        fleet_no: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="login-details" id="login-details">
            <form onSubmit={handleSubmit}>
              <Header />
              <div className="form-section">
                <div className="form-group">
                  <h3 className="md-title">Enter your Customer Account # </h3>
                  <div className="ui fluid input bs-field">
                    <input
                      type="password"
                      className={
                        errors.fleet_no ? "bs-input error" : "bs-input"
                      }
                      value={values.fleet_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fleet_no"
                      placeholder=" "
                    />
                    <label className="bs-label">Customer Account #</label>
                    {message && (
                      <p className="bs-error" id="fleet_no_not_found">
                        A/c # that you entered does not match in our system
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="footer-fixed">
                <div className="actions">
                  {!message && (
                    <div>
                      <PrimaryButton
                        text="Continue"
                        type="submit"
                        id="enter-fleet-no-continue"
                      />
                      {
                        contextValues.webServiceRequestConfig.fleet_lookup_enabled === true &&
                        <BorderInheritButton
                          text="I don’t know my Customer Account #"
                          type="button"
                          id="enter-fleet-no-donnot-have-acc"
                          onClick={customerAccountUnknown}
                        />
                      }
                    </div>
                  )}
                  {message && (
                    <div>
                      <HiddenPrimaryButton
                        text="Start Over"
                        type="button"
                        onClick={startOver}
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}

export default EnterFleetNo;
