import React, { useContext } from "react";
import Select from "../../Components/Select";
import TenantContext from "../../OnlineFormContext";
import VMRSCodes from "./VMRSCodes";

function System({ selected, onChange, equipmentType, equipmentTypeId, hasError }) {
  const contextValues = useContext(TenantContext);
  const vmrsCodes = new VMRSCodes(contextValues.tenantAuthToken, equipmentType, equipmentTypeId);

  return (
    <div className="ui fluid input bs-field system-section">
      <Select
        asynchronous={true}
        paginate={true}
        value={selected}
        loadOptions={async (search, _, { page }) => ({
          ...(await vmrsCodes.systemOptions(search, page)),
          additional: {
            page: page + 1,
          },
        })}
        onChange={(e) => {
          onChange(e);
        }}
        additional={{
          page: 1,
        }}
        hasError={hasError}
        cacheUniq={[selected]}
        placeholder={"System"}
      />
    </div>
  );
}

export default System;
