import React from "react";
import ReachLogo from "./../assets/images/reach_logo.png";

function PoweredByFooter(props) {
  return (
    <div className="footer-fixed h-footer">
      <p className="description">
        Powered by
        <span>
          <img src={ReachLogo} alt="Logo" />
        </span>
      </p>
    </div>
  );
}

export default PoweredByFooter;
