import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { usePosition } from "use-position";
import Geocode from "react-geocode";
import { classnames } from "./../Components/PlacesAutoCompleteHelper.js";
import { BorderInheritRightButton } from "./../Components/Button.js";
import * as CONSTANT from "../Config";

function AddressAutoComplete({
  field,
  form,
  errors,
  touched,
  addressToShow,
  onChange,
  showUseCurrentLoc,
  placeholderText,
  onError,
}) {
  const { latitude, longitude } = usePosition(true, {
    enableHighAccuracy: true,
  });
  const [address, setAddress] = useState(addressToShow);
  const [errorMessage, setErrorMessage] = useState("");

  const extractFromAddress = (components, type) => {
    return (
      components
        .filter((component) => component.types.indexOf(type) === 0)
        .map((item) => item.long_name)
        .pop() || null
    );
  };

  const handleChange = (address) => {
    form.setFieldValue(field.name, address);
    setAddress(address);
    setErrorMessage("");
  };

  const handleSelect = (selected) => {
    setAddress(selected);
    form.setFieldValue(field.name, selected);

    geocodeByAddress(selected)
      .then((res) => {
        let city = extractFromAddress(res[0].address_components, "locality");
        let state = extractFromAddress(
          res[0].address_components,
          "administrative_area_level_1"
        );
        let country = extractFromAddress(res[0].address_components, "country");
        getLatLng(res[0]).then(({ lat, lng }) => {
          onChange(address, lat, lng, city, state, country);
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleError = (status, clearSuggestions) => {
    console.log("Error from Google Maps API", status);
    setErrorMessage(status, () => {
      clearSuggestions();
    });
    if (status === "ZERO_RESULTS") {
      clearSuggestions();
      onError(status);
    }
  };

  const fetchCurrentLocation = () => {
    Geocode.setApiKey(CONSTANT.GOOGLE_MAP_API_KEY);
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        let city = extractFromAddress(
          response.results[0].address_components,
          "locality"
        );
        let state = extractFromAddress(
          response.results[0].address_components,
          "administrative_area_level_1"
        );
        let country = extractFromAddress(
          response.results[0].address_components,
          "country"
        );
        const address = response.results[0].formatted_address;
        form.setFieldValue(field.name, address);
        setAddress(address);
        onChange(address, latitude, longitude, city, state, country);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div>
      <PlacesAutocomplete
        onChange={handleChange}
        value={address}
        onSelect={handleSelect}
        onError={handleError}
        shouldFetchSuggestions={address.length >= 0}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div className="Demo__search-bar-container">
              <div className="Demo__search-input-container">
                <div className="ui fluid input bs-field">
                  <input
                    {...getInputProps({
                      placeholder: " ",
                      className:
                        touched && touched[field.name] && errors[field.name]
                          ? "bs-input error"
                          : "bs-input",
                      name: field.name,
                    })}
                  />
                  <label className="bs-label">{placeholderText}</label>
                </div>
                {showUseCurrentLoc && (
                  <div className="ui two column grid actions qus-toggle">
                    <div className="column t-left"></div>
                    <div className="column">
                      <div className="actions use-current-location t-right">
                        <BorderInheritRightButton
                          text="Use Current Location"
                          onClick={fetchCurrentLocation}
                          type="button"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {suggestions.length > 0 && (
                <div className="Demo__autocomplete-container">
                  {suggestions.map((suggestion) => {
                    const className = classnames("Demo__suggestion-item", {
                      "Demo__suggestion-item--active": suggestion.active,
                    });

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        <strong>
                          {suggestion.formattedSuggestion.mainText}
                        </strong>
                        <br />
                        <small>
                          {suggestion.formattedSuggestion.secondaryText}
                        </small>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
      {errorMessage.length > 0 && (
        <div>
          {errorMessage === "ZERO_RESULTS" ? (
            <div className="bs-error">Please enter valid address</div>
          ) : (
            <div className="bs-error">{errorMessage}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default AddressAutoComplete;
