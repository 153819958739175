import React, { useContext } from "react";
import Select from "../Components/Select";
import VMRSCodes from "./VMRSServices/VMRSCodes";
import TenantContext from "../OnlineFormContext";

function CauseOfFailure({ selected, aarOptions, vmrsAssembly, onChange }) {
  const contextValues = useContext(TenantContext);
  const vmrsCodes = new VMRSCodes(contextValues.tenantAuthToken);

  const options = vmrsAssembly
    ? {
        loadOptions: async (search, _, { page }) => ({
          ...(await vmrsCodes.causeOfFailureOptions(
            vmrsAssembly,
            search,
            page
          )),
          additional: {
            page: page + 1,
          },
        }),
        asynchronous: true,
        paginate: true,
        additional: {
          page: 1,
        },
      }
    : { options: aarOptions };

  return (
    <Select
      value={selected}
      {...options}
      onChange={(e) => {
        onChange(e);
      }}
      placeholder={"Cause of Failure"}
    />
  );
}

export default CauseOfFailure;
