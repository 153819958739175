import React from "react";
import { Field } from "formik";
import System from "./System";
import Subsystem from "./Subsystem";
import Service from "./Service";

const CaptureAARService = ({
  serviceLine,
  equipmentType,  
  equipmentTypeId,
  fieldIndex,
  direction,
}) => {
  const { aarService } = serviceLine;
  const fieldKeys = {
    system: `serviceLines[${fieldIndex}].aarService.system`,
    subsystem: `serviceLines[${fieldIndex}].aarService.subsystem`,
    service: `serviceLines[${fieldIndex}].aarService.service`,
    tireCategory: `serviceLines[${fieldIndex}].tireCategory`,
    isTireAsSystem: `serviceLines[${fieldIndex}].isTireAsSystem`,
    aarPositionOptions: `serviceLines[${fieldIndex}].aarPositionOptions`,
    aarCauseOfFailureOptions: `serviceLines[${fieldIndex}].aarCauseOfFailureOptions`,
    jobId: `serviceLines[${fieldIndex}].aarService.jobId`,
    equipmentCategoryId: `serviceLines[${fieldIndex}].aarService.equipmentCategoryId`,
  };

  const validateAAR = (option) => {
    if (!option) return "Required.";
  };

  return (
    <React.Fragment>
      <div className="ui grid custmpadding">
        <div className="sixteen wide tablet eight wide computer column">
          <Field name={fieldKeys.system} className="bs-input" placeholder=" ">
            {({ field, form, meta }) => (
              <System
                selected={aarService.system}
                onChange={(val) => {
                  form.setFieldValue(field.name, val);
                  form.setFieldValue(fieldKeys.isTireAsSystem, val.isTireAsSystem);
                  form.setFieldValue(fieldKeys.tireCategory, false);
                  form.setFieldValue(fieldKeys.subsystem, null);
                  form.setFieldValue(fieldKeys.service, null);
                }}
                equipmentType={equipmentType}
                equipmentTypeId={equipmentTypeId}
                hasError={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>

        <div className="sixteen wide tablet eight wide computer column">
          <Field
            name={fieldKeys.subsystem}
            className="bs-input"
            placeholder=" "
          >
            {({ field, form, meta }) => (
              <Subsystem
                selected={aarService.subsystem}
                system={aarService.system && aarService.system.value}
                onChange={({ option, system }) => {
                  form.setFieldValue(field.name, option);
                  form.setFieldValue(fieldKeys.service, null);
                  if (!aarService.system)
                    form.setFieldValue(fieldKeys.system, system);
                }}
                equipmentType={equipmentType}
                equipmentTypeId={equipmentTypeId}
                hasError={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
      </div>
      <Field
        name={fieldKeys.service}
        className="bs-input"
        placeholder=" "
        validate={direction === "back" ? null : validateAAR}
      >
        {({ field, form, meta }) => (
          <Service
            selected={aarService.service}
            system={aarService.system && aarService.system.value}
            subsystem={aarService.subsystem && aarService.subsystem.value}
            onChange={({
              option,
              system,
              subsystem,
              tireCategory,
              aarPositionOptions,
              aarCauseOfFailureOptions,
              jobId,
              equipmentCategoryId,
            }) => {
              form.setFieldValue(field.name, option);
              form.setFieldValue(fieldKeys.tireCategory, tireCategory);
              form.setFieldValue(
                fieldKeys.aarPositionOptions,
                aarPositionOptions
              );
              form.setFieldValue(
                fieldKeys.aarCauseOfFailureOptions,
                aarCauseOfFailureOptions
              );
              form.setFieldValue(fieldKeys.jobId, jobId);
              form.setFieldValue(
                fieldKeys.equipmentCategoryId,
                equipmentCategoryId
              );
              if (!aarService.system)
                form.setFieldValue(fieldKeys.system, system);
              if (!aarService.subsystem)
                form.setFieldValue(fieldKeys.subsystem, subsystem);
            }}
            equipmentType={equipmentType}
            equipmentTypeId={equipmentTypeId}
            hasError={meta.touched && meta.error}
          />
        )}
      </Field>
    </React.Fragment>
  );
};

export default CaptureAARService;
