import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { PrimaryButton, BorderInheritButton } from "./../Components/Button.js";
import Input from "./../Components/Input.js";
import Select from "./../Components/Select";
import ReachAPI from "./../Shared/ReachAPI";
import EnterFleetInfo from "./EnterFleetInfo.js";
import TenantContext from "./../OnlineFormContext";

function FleetDetails({ formData, setFormData, nextStep, startOver }) {
  const contextValues = useContext(TenantContext);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);
  const [direction, setDirection] = useState("back");
  const {
    fleetName,
    fleetStreet,
    fleetCity,
    fleetState,
    fleetNo,
    fleetCountry,
  } = formData;

  async function getShipToLocationOptions(search, _, { page }) {
    const res = await reachAPI.shipToAccountNoSearch(
      formData.fleetId,
      search,
      page
    );
    return {
      options: res.options,
      hasMore: res.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <div>
      {formData.newFleet && (
        <EnterFleetInfo
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          startOver={startOver}
        />
      )}

      {!formData.newFleet && (
        <Formik
          initialValues={formData}
          onSubmit={(values) => {
            setFormData(values);
            nextStep();
          }}
        >
          {({ errors, touched, values, isValidating, setFieldValue }) => (
            <Form>
              <div className="form-section review_fleet">
                <div className="form-group custom-input">
                  <div className="sub-title">
                    <h3 className="sm-title">Review your fleet information</h3>
                    {fleetNo && (
                      <p>
                        <i className="check circle icon"></i>
                        Customer Account #{fleetNo.replace(/.(?=.{4})/g, "X")}
                      </p>
                    )}
                  </div>
                  <Input
                    type="text"
                    value={fleetName}
                    readOnly="readOnly"
                    label_name="Fleet Name"
                    required="required"
                  />
                  <Input
                    type="text"
                    value={fleetStreet}
                    readOnly="readOnly"
                    label_name="Fleet Street Address"
                    required="required"
                  />
                  {fleetCity && (
                    <Input
                      type="text"
                      value={fleetCity}
                      readOnly="readOnly"
                      label_name="Fleet City"
                      required="required"
                    />
                  )}
                  {fleetState && (
                    <Input
                      type="text"
                      value={fleetState}
                      readOnly="readOnly"
                      label_name="Fleet State"
                      required="required"
                    />
                  )}
                  {fleetCountry && (
                    <Input
                      type="text"
                      value={fleetCountry}
                      readOnly="readOnly"
                      label_name="Fleet Country/Region"
                      required="required"
                    />
                  )}

                  {contextValues.oem && !fleetNo && (
                    <div className="ui fluid input bs-field">
                      <div className="sub-title">
                        <h4 className="sm-title">Choose Ship-to Location</h4>
                      </div>
                      <Field
                        name={"fleetNo"}
                        className="bs-input"
                        placeholder=" "
                      >
                        {({ field, form, meta }) => (
                          <Select
                            defaultValue={
                              fleetNo
                                ? { label: values.label, value: values.fleetNo }
                                : null
                            }
                            asynchronous={true}
                            isClearable
                            paginate={true}
                            placeholder="Choose Ship-to Location"
                            loadOptions={getShipToLocationOptions}
                            additional={{
                              page: 1,
                            }}
                            onChange={(opt) => {
                              setFieldValue(field.name, null);
                              setFieldValue("label", "");
                              if (opt) {
                                setFieldValue(field.name, opt.value);
                                setFieldValue("label", opt.label);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </div>
              </div>
              <div className="footer-fixed">
                <div className="actions">
                  <PrimaryButton
                    text="Yes, this is my fleet"
                    onClick={() => setDirection("forward")}
                    type="submit"
                  />
                  <BorderInheritButton
                    text="No, this is not my fleet"
                    onClick={startOver}
                    type="button"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

FleetDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default FleetDetails;
