import { useContext } from "react";
import axios from "axios";
import TenantContext from "./../../OnlineFormContext";

class AARCodes {
  constructor(authToken, equipmentType, equipmentTypeId) {
    this.authToken = authToken;
    this.equipmentType = equipmentType;
    this.equipmentTypeId = equipmentTypeId;
    const contextValues = useContext(TenantContext);
    this.apiEndpoint = contextValues.apiEndpoint;
  }

  async systemOptions(search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/system`, {
        params: {
          equipment_type: this.equipmentType,
          equipment_type_id: this.equipmentTypeId,
          search: search,
          page: page,
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.service_components.map((option) => ({
        label: option.name,
        value: option.id,
        isTireAsSystem: option.service_type_tire
      })),
      hasMore: response.meta.has_more,
    };
  }

  async subsystemOptions(system, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/sub_system`, {
        params: {
          equipment_type: this.equipmentType,
          equipment_type_id: this.equipmentTypeId,
          search: search,
          page: page,
          ...(system !== null && { service_component: system }),
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.sub_service_components.map((option) => ({
        label: option.name,
        value: option.id,
        system: {
          label: option.service_component_name,
          value: option.service_component_id,
        },
      })),
      hasMore: response.meta.has_more,
    };
  }

  async serviceOptions(system, subsystem, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/service`, {
        params: {
          equipment_type: this.equipmentType,
          equipment_type_id: this.equipmentTypeId,
          search: search,
          page: page,
          ...(system && { service_component: system }),
          ...(subsystem && { sub_service_component: subsystem }),
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.services.map((option) => ({
        label: option.name,
        value: option.id,
        system: {
          label: option.service_component_name,
          value: option.service_component_id,
        },
        subsystem: {
          label: option.sub_component_name,
          value: option.sub_component_id,
        },
        tireCategory: option.tire_type_service,
        aarPositionOptions: option.jobs[0].component_locations.map((loc) => ({
          label: loc.code_with_desc,
          value: loc.id,
        })),
        aarCauseOfFailureOptions: option.jobs[0].defects.map((cause) => ({
          label: cause.code_with_desc,
          value: cause.id,
        })),
        jobId: option.jobs[0].id,
        equipmentCategoryId: option.category_id,
      })),
      hasMore: response.meta.has_more,
    };
  }
}

export default AARCodes;
