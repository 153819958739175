import React from "react";
import { default as ReactSelect, components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncPaginate from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";

function Select(props) {
  const { ValueContainer, Placeholder } = components;
  const {
    asynchronous,
    paginate,
    hasError,
    hasGroupOption,
    hasCreatable,
    ...options
  } = props;

  const Component = hasCreatable
    ? CreatableSelect
    : asynchronous
    ? paginate
      ? AsyncPaginate
      : AsyncSelect
    : ReactSelect;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const formatGroupLabel = (data) => (
    <span style={{ color: "#000", fontWeight: "bold" }}>{data.label}</span>
  );

  return (
    <Component
      debounceTimeout={300}
      components={{
        IndicatorSeparator: () => null,
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          borderColor: hasError ? "#cc0d0d" : "#d6d6d6",
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? 5 : "50%",
          transition: "top 0.1s, font-size 0.1s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        }),
        option: (base, state) => ({
          ...base,
          paddingLeft: hasGroupOption ? 25 : "",
        }),
      }}
      formatGroupLabel={formatGroupLabel}
      {...options}
    />
  );
}

export default Select;
