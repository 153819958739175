import React from "react";

function Input(props) {
  return (
    <div className="ui fluid input bs-field">
      <input
        type={props.text}
        className="bs-input"
        required={props.required}
        readOnly={props.readOnly}
        value={props.value}
      />
      <label className="bs-label">{props.label_name}</label>
    </div>
  );
}

export default Input;
