import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "./base.css";
import "./styles.css";
import "./main.css";
import "./react-datetime.css";
import "./EventSchedule.css";

//import './index.css'; This is from scaffold
// import 'semantic-ui-css/semantic.min.css';
import EnvironmentSelection from "./EnvironmentSelection";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<EnvironmentSelection />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
