import React, { useContext } from "react";
import Select from "../../Components/Select";
import TenantContext from "../../OnlineFormContext";
import VMRSCodes from "./VMRSCodes";

function WorkToBeAccomplished({ assembly, selected, onChange }) {
  const contextValues = useContext(TenantContext);
  const vmrsCodes = new VMRSCodes(contextValues.tenantAuthToken);

  return (
    <div className="ui fluid input bs-field system-section">
      <Select
        asynchronous={true}
        paginate={true}
        value={selected}
        loadOptions={async (search, _, { page }) => ({
          ...(await vmrsCodes.workToBeAccomplishedOptions(
            assembly,
            search,
            page
          )),
          additional: {
            page: page + 1,
          },
        })}
        onChange={({ label, value }) => {
          onChange({
            option: { label, value },
          });
        }}
        additional={{
          page: 1,
        }}
        cacheUniq={[selected]}
        placeholder={"Work to be Accomplished "}
      />
    </div>
  );
}

export default WorkToBeAccomplished;
