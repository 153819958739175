import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import MaskedInput from "react-text-mask";
import { Segment } from "semantic-ui-react";
import * as yup from "yup";
import Select from "../Components/Select.js";
import { DCLI_CC } from "../utils/WebFormDefs.js";
import { PrimaryButton, SecondaryButton } from "./../Components/Button.js";
import TenantContext from "./../OnlineFormContext";
import NearestTenant from "./NearestTenants.js";
import ReportedLocation from "./ReportedLocation.js";

const breakdownOptions = [
  { value: "Shipper DC", label: "Shipper DC" },
  { value: "Trucker Yard", label: "Trucker Yard" },
  { value: "Roadside Emergency", label: "Roadside Emergency" },
];

const chassisContainerMask = [
  /[A-Za-z]/,
  /[A-Za-z]/,
  /[A-Za-z]/,
  /[A-Za-z]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const validationSchema = yup.object({
  equipmentType: yup.string().required("Should select equipment type"),
  equipmentNo: yup
    .string()
    .matches(/^[a-z0-9]+$/i)
    .required("EP number is required"),
  assChassis: yup.string().matches(/^[a-z0-9]+$/i),
  assContainer: yup.string().matches(/^[a-z0-9]+$/i)
});

function EquipmentDetails({ formData, setFormData, nextStep, prevStep }) {
  const validate = (option) => {
    if (!option) return "Required.";
  };
  const [direction, setDirection] = useState("back");
  const contextValues = useContext(TenantContext);
  const nonLegacyEquipment = (equipment) => ["tractor", "trailer", "chassis", "container"].indexOf(equipment.equipmentType) == -1

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
        validationSchema={direction === "back" ? null : validationSchema}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div
              className={
                values.equipmentType
                  ? "form-section review_fleet custom-position"
                  : "form-section review_fleet"
              }
            >
              {/* Configure Equipment to Chassis #1 in DCLI webform and hide the `Which equipment has issues?` field */}
              {contextValues.tenantName !== DCLI_CC && (
                <div className="form-group">
                  <h4 className="qus-title">Which equipment has issues?</h4>
                  <div className="ui two column grid equipment-list">
                    {contextValues.enabledEquipmentTypes.map(
                      (eqType, index) => (
                        <div className="column" key={index}>
                          <div>
                            <label>
                              <Segment
                                className={
                                  values.equipmentTypeDescription === eqType.description
                                    ? "equipmentTypeSelected"
                                    : ""
                                }
                              >
                                <Field
                                  type="radio"
                                  name="equipmentType"
                                  value={eqType.id}
                                  onChange={() => {
                                    setFieldValue(
                                      "vmrs",
                                      eqType.service_coding_standard === "vmrs"
                                    );
                                    setFieldValue("equipmentType", eqType.code);
                                    setFieldValue("equipmentTypeId", eqType.id);
                                    setFieldValue(
                                      "serviceCodingStandard",
                                      eqType.service_coding_standard
                                    );
                                    setFieldValue("equipmentNo", "");
                                    setFieldValue("refrigerated", false);
                                    setFieldValue("assTractor", "");
                                    setFieldValue("assTrailer", "");
                                    setFieldValue("assChassis", "");
                                    setFieldValue("assContainer", "");
                                    setFieldValue("equipmentTypeDescription", eqType.description)
                                  }}
                                />
                                {eqType.description}
                              </Segment>
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {values.equipmentType && (
                <div className="form-group">
                  <h4 className="qus-title">
                    {contextValues.tenantName === DCLI_CC &&
                    values.equipmentType === "chassis"
                      ? "Enter Chassis Information:"
                      : "Enter equipment information:"}
                  </h4>
                  <div className="ui fluid input bs-field">
                    {(values.equipmentType === "chassis" ||
                      values.equipmentType === "container") && (
                      <Field name="equipmentNo">
                        {({ field, form, meta }) => (
                          <MaskedInput
                            {...field}
                            mask={chassisContainerMask}
                            id="equipmentNo"
                            placeholder=" "
                            type="text"
                            error={touched.equipmentNo && errors.equipmentNo}
                            className={
                              errors.equipmentNo ? "bs-input error" : "bs-input"
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    )}

                    {(values.equipmentType === "tractor" ||
                      values.equipmentType === "trailer") && (
                      <Field
                        name="equipmentNo"
                        className={
                          errors.equipmentNo ? "bs-input error" : "bs-input"
                        }
                        error={touched.equipmentNo && errors.equipmentNo}
                        placeholder=" "
                        autoComplete="off"
                      />
                    )}

                    {nonLegacyEquipment(values) && (
                      <Field
                        name="equipmentNo"
                        className={
                          errors.equipmentNo ? "bs-input error" : "bs-input"
                        }
                        error={touched.equipmentNo && errors.equipmentNo}
                        placeholder=" "
                        autoComplete="off"
                      />
                    )}

                    {values.equipmentType === "chassis" && (
                      <label className="bs-label">Chassis #</label>
                    )}
                    {values.equipmentType === "container" && (
                      <label className="bs-label">Container #</label>
                    )}
                    {values.equipmentType === "tractor" && (
                      <label className="bs-label">Power Unit #</label>
                    )}
                    {values.equipmentType === "trailer" && (
                      <label className="bs-label">Trailer #</label>
                    )}
                    {nonLegacyEquipment(values) && (
                      <label className="bs-label">{values.equipmentTypeDescription} #</label>
                    )}
                  </div>

                  {contextValues.tenantName === DCLI_CC &&
                    values.equipmentType === "chassis" && (
                      <div className="ui fluid input bs-field">
                        <div className="ui two column grid actions qus-toggle">
                          <div className="column t-left">Bare Chassis</div>
                          <div className="column t-right">
                            <label className="switch">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name={"bare_chassis"}
                                  checked={values.bare_chassis}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "bare_chassis",
                                      e.target.checked
                                    );
                                    setFieldValue(
                                      "assContainer",
                                      contextValues.tenantName === DCLI_CC &&
                                        e.target.checked
                                        ? "BARE000000"
                                        : "",
                                      true
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Hide Vehicle Type & Color field for DCLI webform */}
                  {contextValues.tenantName !== DCLI_CC && (
                    <div className="ui fluid input bs-field">
                      <Field
                        name="vehTypeColor"
                        className="bs-input"
                        placeholder=" "
                        autoComplete="off"
                      />
                      <label className="bs-label">
                        Vehicle Type &amp; Color (optional)
                      </label>
                    </div>
                  )}

                  {(values.equipmentType === "container" ||
                    values.equipmentType === "tractor") && (
                    <div className="ui fluid input bs-field">
                      <Field name="assChassis">
                        {({ field, form, meta }) => (
                          <MaskedInput
                            {...field}
                            mask={chassisContainerMask}
                            placeholder=" "
                            type="text"
                            error={touched.assChassis && errors.assChassis}
                            className={
                              errors.assChassis ? "bs-input error" : "bs-input"
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                      <label className="bs-label">
                        Associated Chassis # (optional)
                      </label>
                    </div>
                  )}

                  {(values.equipmentType === "chassis" ||
                    values.equipmentType === "tractor") && (
                    <div className="ui fluid input bs-field">
                      <Field
                        name="assContainer"
                        validate={
                          direction === "back" ||
                          contextValues.tenantName !== DCLI_CC
                            ? null
                            : validate
                        }
                      >
                        {({ field, form, meta }) => (
                          <MaskedInput
                            {...field}
                            name={field.name}
                            mask={chassisContainerMask}
                            placeholder=" "
                            type="text"
                            error={touched.assContainer && errors.assContainer}
                            className={
                              touched.assContainer && errors.assContainer
                                ? "bs-input error"
                                : "bs-input"
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                      <label className="bs-label">
                        Associated Container #{" "}
                        {contextValues.tenantName !== DCLI_CC
                          ? " (optional)"
                          : ""}
                      </label>
                    </div>
                  )}

                  {/* Hide Associated Power Unit field for DCLI webform */}
                  {contextValues.tenantName !== DCLI_CC &&
                    (values.equipmentType === "chassis" ||
                      values.equipmentType === "container" ||
                      values.equipmentType === "trailer") && (
                      <div className="ui fluid input bs-field">
                        <Field
                          name="assTractor"
                          className="bs-input"
                          placeholder=" "
                          autoComplete="off"
                        />
                        <label className="bs-label">
                          Associated Power Unit # (optional)
                        </label>
                      </div>
                    )}

                  {values.equipmentType === "trailer" && (
                    <div className="ui fluid input bs-field">
                      <div className="ui two column grid actions qus-toggle">
                        <div className="column t-left">Refrigerated</div>
                        <div className="column t-right">
                          <label className="switch">
                            <Field name="refrigerated">
                              {({ field, form, meta }) => {
                                return (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={field.value}
                                      {...field}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                );
                              }}
                            </Field>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {values.equipmentType === "tractor" && (
                    <div className="ui fluid input bs-field">
                      <Field
                        name="assTrailer"
                        className="bs-input"
                        placeholder=" "
                        autoComplete="off"
                      />
                      <label className="bs-label">
                        Associated Trailer # (optional)
                      </label>
                    </div>
                  )}

                  {((contextValues.tenantName === DCLI_CC &&
                    values.bare_chassis === false) ||
                    contextValues.tenantName !== DCLI_CC) && (
                    <div className="ui fluid input bs-field">
                      <div className="ui two column grid actions qus-toggle">
                        <div className="column t-left">
                          {contextValues.tenantName === DCLI_CC ? "Container loaded?" : "Is the vehicle loaded?"}
                        </div>
                        <div className="column t-right">
                          <label className="switch">
                            <Field name="vehicleLoaded">
                              {({ field, form, meta }) => {
                                return (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={field.value}
                                      {...field}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                );
                              }}
                            </Field>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {values.equipmentType && (
                <div className="form-group">
                  <h4 className="qus-title">Where did the breakdown occur?</h4>
                  <div className="ui fluid input bs-field">
                    <Field
                      name="reportedLocation"
                      className="bs-input"
                      placeholder=" "
                      validate={() => {
                        if(direction !== "back" && !values.reportedLat && !values.reportedLon)
                          return "Required."
                      }}
                    >
                      {({ field, form, meta }) => (
                        <ReportedLocation
                          field={field}
                          form={form}
                          errors={errors}
                          reportedLocationValue={formData.reportedLocation}
                          onChange={(
                            address,
                            lat,
                            lon,
                            city,
                            state,
                            country
                          ) => {
                            form.setFieldValue("reportedLat", lat);
                            form.setFieldValue("reportedLon", lon);
                            form.setFieldValue("reportedCity", city);
                            form.setFieldValue("reportedState", state);
                            form.setFieldValue("reportedCountry", country);
                            form.setFieldValue("service_center_id", "");
                          }}
                          onError={(status) => {
                            form.setFieldValue("reportedLat", "");
                            form.setFieldValue("reportedLon", "");
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {contextValues.webServiceRequestConfig.event_sc_option !=
                    "self" && (
                    <div className="custom-input nearest-field">
                      <div className="ui fluid input bs-field">
                        <Field
                          name="service_center_id"
                          validate={direction === "back" ? null : validate}
                        >
                          {({ field, form, meta }) => (
                            <NearestTenant
                              form={form}
                              hasError={meta.error}
                              onChange={(val) => {
                                setFieldValue("service_center_id", val);
                              }}
                              placeholder={"Choose the service provider"}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}

                  {contextValues.tenantName === DCLI_CC && (
                    <div className="custom-input nearest-field">
                      <div className="ui fluid input bs-field">
                        <Field
                          name="breakdown_location"
                          className="bs-input"
                          placeholder=" "
                        >
                          {({ field, form, meta }) => (
                            <Select
                              options={breakdownOptions}
                              value={
                                values.breakdown_location
                                  ? breakdownOptions.find(
                                      (opt) =>
                                        opt.value === values.breakdown_location
                                    )
                                  : ""
                              }
                              onChange={(val) => {
                                form.setFieldValue(field.name, val.value);
                              }}
                              placeholder={"Breakdown Location"}
                              hasError={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}

                  <div className="ui fluid input bs-field">
                    <Field
                      name="breakdownNotes"
                      className="bs-input"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label className="bs-label">
                      Breakdown Notes (optional)
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="footer-fixed">
              <div className="ui two column grid actions">
                <div className="column">
                  <SecondaryButton
                    text="Back"
                    type="submit"
                    onClick={() => setDirection("back")}
                  />
                </div>
                <div className="column">
                  <PrimaryButton
                    text="Next"
                    onClick={() => setDirection("forward")}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

EquipmentDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default EquipmentDetails;
