import React, { useContext, useEffect, useState } from "react";
import TenantContext from "./../OnlineFormContext";
import { PrimaryButton, SecondaryButton } from "./../Components/Button.js";
import HelplineMsg from "./../Components/HelplineMsg.js";
import { DCLI_CC } from "../utils/WebFormDefs.js";

function LandingOptions({ setCurrentState, setFleetDetails, startOver }) {
  const contextValues = useContext(TenantContext);

  const getStarted = () => {
    if (contextValues.tenantName === DCLI_CC) {
      setFleetDetails({
        fleetInfo: {
          name: "",
          addresses: [
            {
              address: "",
              city: "",
              state: "",
              country: "",
            },
          ],
          newFleet: true,
          newFleetAddress: "",
          newFleetScacCode: "",
          newFleetDotNumber: "",
          newFleetEmail: "",
          newFleetPhone: "",
        },
        fleetNo: null,
      });
    } else {
      setCurrentState("searchFleets");
    }
  };

  const getStartedNewEvent = () => {
    setFleetDetails({
      fleetInfo: {
        name: "",
        addresses: [
          {
            address: "",
            city: "",
            state: "",
            country: "",
          },
        ],
        newFleet: true,
        newFleetAddress: "",
        newFleetScacCode: "",
        newFleetDotNumber: "",
        newFleetEmail: "",
        newFleetPhone: "",
      },
      fleetNo: null,
    });
  };

  const conditions = () => {
    switch (true) {
      case contextValues.webServiceRequestConfig
        .customer_account_lookup_enabled === true &&
        contextValues.webServiceRequestConfig.fleet_lookup_enabled === true &&
        contextValues.webServiceRequestConfig.enable_new_customer === true:
        return (
          <div>
            {
              <PrimaryButton
                text="I have a Customer Account #"
                onClick={() => setCurrentState("captcha")}
                type="button"
              />
            }
            <SecondaryButton
              text={"I don’t have a Customer Account #"}
              type="button"
              onClick={() => setCurrentState("searchFleets")}
            />
          </div>
        );
      case contextValues.webServiceRequestConfig
        .customer_account_lookup_enabled === true &&
        contextValues.webServiceRequestConfig.fleet_lookup_enabled === true:
        return (
          <div>
            {
              <PrimaryButton
                text="I have a Customer Account #"
                onClick={() => setCurrentState("captcha")}
                type="button"
              />
            }
            <SecondaryButton
              text={"I don’t have a Customer Account #"}
              type="button"
              onClick={() => setCurrentState("searchFleets")}
            />
          </div>
        );
      case contextValues.webServiceRequestConfig
        .customer_account_lookup_enabled === true &&
        contextValues.webServiceRequestConfig.enable_new_customer === true:
        return (
          <div>
            <PrimaryButton
              text="I have a Customer Account #"
              onClick={() => setCurrentState("captcha")}
              type="button"
            />
            <SecondaryButton
              text="Request service for new fleet"
              onClick={getStartedNewEvent}
              type="button"
            />
          </div>
        );
      case contextValues.webServiceRequestConfig
        .customer_account_lookup_enabled === true:
        return (
          <div>
            <PrimaryButton
              text="I have a Customer Account #"
              onClick={() => setCurrentState("captcha")}
              type="button"
            />
          </div>
        );
      case contextValues.webServiceRequestConfig.fleet_lookup_enabled === true:
        return (
          <div>
            <PrimaryButton
              text="Get Started"
              onClick={getStarted}
              type="button"
            />
          </div>
        );
      case contextValues.webServiceRequestConfig.enable_new_customer === true:
        return (
          <div>
            <PrimaryButton
              text="Get Started"
              onClick={getStartedNewEvent}
              type="button"
            />
          </div>
        );

      default:
    }
  };

  return (
    <div className="actions">
      <h3 className="sm-title">Request Service</h3>
      {conditions()}
      <HelplineMsg />
    </div>
  );
}

export default LandingOptions;
