import React from "react";
import { Field } from "formik";
import System from "./System";
import Assembly from "./Assembly";
import Component from "./Component";
import WorkToBeAccomplished from "./WorkToBeAccomplished";

const CaptureVMRSService = ({
  serviceLine,
  equipmentType,
  equipmentTypeId,
  fieldIndex,
  direction,
}) => {
  const { vmrsService } = serviceLine;
  const fieldKeys = {
    system: `serviceLines[${fieldIndex}].vmrsService.system`,
    assembly: `serviceLines[${fieldIndex}].vmrsService.assembly`,
    component: `serviceLines[${fieldIndex}].vmrsService.component`,
    tireCategory: `serviceLines[${fieldIndex}].tireCategory`,
    isTireAsSystem: `serviceLines[${fieldIndex}].isTireAsSystem`,
    equipmentCategoryId: `serviceLines[${fieldIndex}].vmrsService.equipmentCategoryId`,
    workToBeAccomplished: `serviceLines[${fieldIndex}].vmrsService.workToBeAccomplished`,
  };

  const validateIssueDetail = (option) => {
    if (!option) return "Required.";
  };

  return (
    <React.Fragment>
      <div className="ui grid custmpadding">
        <div className="sixteen wide tablet eight wide computer column">
          <Field
            name={fieldKeys.system}
            className="bs-input"
            placeholder=" "
            validate={direction === "back" ? null : validateIssueDetail}
          >
            {({ field, form, meta }) => (
              <System
                selected={vmrsService.system}
                onChange={(val) => {
                  form.setFieldValue(field.name, val);
                  form.setFieldValue(fieldKeys.isTireAsSystem, val.isTireAsSystem);
                  form.setFieldValue(fieldKeys.tireCategory, false);
                  form.setFieldValue(fieldKeys.assembly, null);
                  form.setFieldValue(fieldKeys.component, null);
                }}
                equipmentType={equipmentType}
                equipmentTypeId={equipmentTypeId}
                hasError={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className="sixteen wide tablet eight wide computer column">
          <Field
            name={fieldKeys.assembly}
            className="bs-input"
            placeholder=" "
            validate={direction === "back" ? null : validateIssueDetail}
          >
            {({ field, form, meta }) => (
              <Assembly
                selected={vmrsService.assembly}
                system={vmrsService.system && vmrsService.system.value}
                onChange={({
                  option,
                  system,
                  tireCategory,
                  equipmentCategoryId,
                }) => {
                  form.setFieldValue(field.name, option);
                  form.setFieldValue(fieldKeys.tireCategory, tireCategory);
                  form.setFieldValue(
                    fieldKeys.equipmentCategoryId,
                    equipmentCategoryId
                  );
                  form.setFieldValue(fieldKeys.component, null);
                  if (!vmrsService.system)
                    form.setFieldValue(fieldKeys.system, system);
                }}
                equipmentType={equipmentType}
                equipmentTypeId={equipmentTypeId}
                hasError={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="ui grid custmpadding">
        <div className="sixteen wide tablet eight wide computer column">
          <Field
            name={fieldKeys.component}
            className="bs-input"
            placeholder=" "
          >
            {({ field, form, meta }) => (
              <Component
                selected={vmrsService.component}
                system={vmrsService.system && vmrsService.system.value}
                assembly={vmrsService.assembly && vmrsService.assembly.value}
                onChange={({ option, system, assembly, equipmentCategoryId }) => {
                  form.setFieldValue(field.name, option);
                  if (!vmrsService.system)
                    form.setFieldValue(fieldKeys.system, system);
                  if (!vmrsService.assembly)
                    form.setFieldValue(fieldKeys.assembly, assembly);
                  form.setFieldValue(fieldKeys.equipmentCategoryId, equipmentCategoryId);
                }}
                equipmentType={equipmentType}
                equipmentTypeId={equipmentTypeId}
                hasError={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        {vmrsService.assembly && (
          <div className="sixteen wide tablet eight wide computer column">
            <Field
              name={fieldKeys.workToBeAccomplished}
              className="bs-input"
              placeholder=" "
            >
              {({ field, form, meta }) => (
                <WorkToBeAccomplished
                  selected={vmrsService.workToBeAccomplished}
                  assembly={vmrsService.assembly && vmrsService.assembly.value}
                  onChange={({ option }) => {
                    form.setFieldValue(field.name, option);
                  }}
                />
              )}
            </Field>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CaptureVMRSService;
