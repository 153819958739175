import React, { useState, useContext } from "react";
import FleetDetails from "../FleetDetails";
import EquipmentDetails from "../EquipmentDetails";
import ServiceLines from "../ServiceLines";
import EventDetails from "../EventDetails";
import DriverDetails from "../DriverDetails";
import Success from "../Success";
import Header from "./../Components/Header.js";
import SubHeader from "./../Components/SubHeader.js";
import TenantContext from "./../OnlineFormContext";
import { DCLI_CC } from "../utils/WebFormDefs";

function EventForm(props) {
  const contextValues = useContext(TenantContext);
  const previewSteps = contextValues.tenantName === DCLI_CC ? " of 4" : " of 5";
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    fleetId: props.fleetInfo.id,
    crosswalkEntryId: props.fleetInfo.crosswalkEntryId,
    fleetName: props.fleetInfo.name,
    truckDispatchId: props.fleetInfo.truck_dispatch_id,
    fleetStreet: props.fleetInfo.addresses[0].address,
    fleetCity: props.fleetInfo.addresses[0].city,
    fleetState: props.fleetInfo.addresses[0].state,
    fleetCountry:
      props.fleetInfo.addresses[0].country == null
        ? ""
        : props.fleetInfo.addresses[0].country,
    fleetNo: props.fleetNo,
    equipmentNo: "",
    vehTypeColor: "",
    assTractor: "",
    assContainer: "",
    assChassis: "",
    assTrailer: "",
    breakdownNotes: "",
    equipmentTypeValues: { equipmentType: "trailer" },
    vehicleLoaded: contextValues.tenantName === DCLI_CC ? false : true,
    equipmentProviderId: props.fleetInfo.iep_id,
    equipmentProviderName: props.fleetInfo.name,
    reportedLocation: "",
    reportedLat: "",
    reportedLon: "",
    reportedCity: "",
    reportedState: "",
    reportedCountry: "",
    vmrs: contextValues.tenantName === DCLI_CC ? false : true,
    tireSize: "",
    oldTireTreadPattern: "",
    newTireTreadPattern: "",
    serviceLines: [],
    reference: "",
    po: "",
    additionalDetails: "",
    paymentMethod: null,
    paymentAccountNumber: "",
    driverName: "",
    driverEmail: "",
    driverPhone: "",
    driverWithUnit: true,
    isDriverReporting: true,
    reporterName: "",
    reporterEmail: "",
    reporterPhone: "",
    driverNotificationMethod: "",
    reporterNotificationMethod: "",
    refrigerated: false,
    newFleetName: props.fleetInfo.name,
    newFleetAddress: props.fleetInfo.newFleetAddress,
    newFleetScacCode: props.fleetInfo.newFleetScacCode,
    newFleetDotNumber: props.fleetInfo.newFleetDotNumber,
    newFleetEmail: props.fleetInfo.newFleetEmail,
    newFleetPhone: props.fleetInfo.newFleetPhone,
    newFleet: props.fleetInfo.newFleet,
    newFleetCity: props.fleetInfo.newFleetCity,
    newFleetState: props.fleetInfo.newFleetState,
    newFleetCountry: props.fleetInfo.newFleetCountry,
    newFleetLat: "",
    newFleetLon: "",
    emergency: false,
    neededBy: "",
    unitAvailable: "",
    equipmentType: contextValues.tenantName === DCLI_CC ? "chassis" : "",
    equipmentTypeId: contextValues.tenantName === DCLI_CC ? 1 : null,
    serviceCodingStandard: contextValues.tenantName === DCLI_CC ? "aar" : "",
    bare_chassis: false,
  });
  /**
   * Skip Step 4 for DCLI webform
   */
  const nextStep = () => {
    window.scrollTo({top: 0});
    setStep((prev) =>
      contextValues.tenantName === DCLI_CC && prev === 3 ? prev + 2 : prev + 1
    );
  }
  const prevStep = () => {
    window.scrollTo({top: 0});
    setStep((prev) =>
      contextValues.tenantName === DCLI_CC && prev === 5 ? prev - 2 : prev - 1
    );
  }
  const currentFormStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <SubHeader
              progress="20"
              step={step + previewSteps}
              title="Fleet"
              subtitle="Next: Equipment"
            />
            <FleetDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              startOver={props.startOver}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <SubHeader
              progress="40"
              step={step + previewSteps}
              title="Equipment"
              subtitle="Next: Issue"
            />
            <EquipmentDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <SubHeader
              progress="60"
              step={step + previewSteps}
              title="Issue"
              subtitle={
                contextValues.tenantName === DCLI_CC
                  ? "Next: Driver"
                  : "Next: Event"
              }
            />
            <ServiceLines
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <SubHeader
              progress="80"
              step={step + previewSteps}
              title="Event"
              subtitle="Next: Driver"
            />
            <EventDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </div>
        );
      case 5:
        return (
          <div>
            <SubHeader
              progress="100"
              step={
                (contextValues.tenantName === DCLI_CC ? step - 1 : step) +
                previewSteps
              }
              title="Driver"
              subtitle="Almost done!"
            />
            <DriverDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </div>
        );
      default:
        return <Success startOver={props.startOver} />;
    }
  };

  return (
    <div className={step !== 6 ? "equipment" : ""}>
      {step !== 6 && <Header />}
      {currentFormStep()}
    </div>
  );
}

export default EventForm;
