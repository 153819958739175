import axios from "axios";
import moment from "moment-timezone";
import { useContext } from "react";
import { DCLI_CC } from "../utils/WebFormDefs";
import TenantContext from "./../OnlineFormContext";

class ReachAPI {
  constructor(authToken, apiEndpoint) {
    this.authToken = authToken;
    const contextValues = useContext(TenantContext);
    this.tenantName = contextValues.tenantName;
    this.dcliPaymentDefaults =
      contextValues.webServiceRequestConfig["default_payment_method_id"];
    this.apiEndpoint = contextValues.apiEndpoint;
  }

  async paymentMethodOptions(fleetId, fleetNo) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/payment_method`, {
        params: { tenant_id: fleetId, account_no: fleetNo },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
    let paymentTypes = response.online_form.map(
      (option) => option.payment_type
    );
    let uniquePaymentTypes = [...new Set(paymentTypes)];
    return uniquePaymentTypes.map((option) => ({
      label: option,
      options: response.online_form
        .map((opt) => {
          if (opt.payment_type === option) {
            return {
              label: opt.payment_name,
              value: opt.id,
              group: opt.payment_type,
              accountRequired: opt.account_required,
              accountNumner: opt.account_no,
            };
          } else {
            return {};
          }
        })
        .filter((item) => Object.keys(item).length),
    }));
  }

  async submitOnlineForm(values, serviceCenterId) {
    var eventServiceAttributes = "";
    if (values.vmrs) {
      eventServiceAttributes = values.serviceLines.map((val) => ({
        vmrs_system_code: val.vmrsService.system.value,
        vmrs_assembly_code: val.vmrsService.assembly.value,
        vmrs_component_code: val.vmrsService.component?.value,
        applied_tire_size: val.tireSize?.value,
        removed_tire_size: val.tireSize?.value,
        removed_tire_tread_pattern: val.oldTireTreadPattern,
        applied_tire_tread_pattern: val.newTireTreadPattern,
        vmrs_position_id: val.position?.value,
        vmrs_failure_code_id: val.causeOfFailure?.value,
        comment: val.comments,
        equipment_category_id: val.vmrsService.equipmentCategoryId,
        event_service_jobs_attributes: [],
        applied_tire_brand: val.newTireBrand?.value,
        removed_tire_brand: val.oldTireBrand?.value,
        vmrs_wa_code_id: val.vmrsService.workToBeAccomplished?.value,
        tire_disposition: val.disPositionOfTire?.value,
      }));
    } else {
      eventServiceAttributes = values.serviceLines.map((val) => ({
        service_component_id: val.aarService.system.value,
        sub_service_component_id: val.aarService.subsystem.value,
        service_id: val.aarService.service.value,
        comment: val.comments,
        event_service_jobs_attributes: [
          {
            job_id: val.aarService.jobId,
            defect_id: val.causeOfFailure?.value,
            component_location_id: val.position?.value,
          },
        ],
        equipment_category_id: val.aarService.equipmentCategoryId,
        applied_tire_size: val.tireSize,
        removed_tire_size: val.tireSize,
        removed_tire_tread_pattern: val.oldTireTreadPattern,
        applied_tire_tread_pattern: val.newTireTreadPattern,
        applied_tire_brand: val.newTireBrand?.value,
        removed_tire_brand: val.oldTireBrand?.value,
        tire_disposition: val.disPositionOfTire?.value,
      }));
    }

    const toUTC = (input) => {
      var fmt = "MM/DD/YYYY hh:mm A";
      var zone = moment.tz.guess();
      return input ? moment.tz(input, fmt, zone).utc().format(fmt) : null;
    };

    let web_service_request = {
      incident: {
        truck_dispatch_id: values.truckDispatchId,
        iep_id: values.equipmentProviderId,
        driver_name: values.driverName || null,
        driver_phone: values.driverPhone || null,
        driver_email: values.driverEmail || null,
        breakdown_notes: values.breakdownNotes,
        loaded: values.vehicleLoaded,
        driver_with_unit: values.driverWithUnit,
        associated_tractor_number: values.assTractor,
        associated_trailer_number: values.assTrailer,
        associated_chassis_number: values.assChassis,
        associated_container_number: values.assContainer,
        service_center_id:
          values.service_center_id && values.service_center_id.value
            ? values.service_center_id.value
            : serviceCenterId,
        reference_work_order_number:
          this.tenantName === DCLI_CC
            ? values.newFleetScacCode
            : values.reference,
        vmrs_service: values.vmrs,
        equipment: {
          vehicle_type_color: values.vehTypeColor,
          equipment_number: values.equipmentNo,
          equipment_type: values.equipmentType,
          equipment_type_id: values.equipmentTypeId,
          refrigerated: values.refrigerated,
        },
        address_attributes: {
          address: values.reportedLocation,
          latitude: values.reportedLat,
          longitude: values.reportedLon,
          city: values.reportedCity,
          state: values.reportedState,
          country: values.reportedCountry,
        },
        event_services_attributes: eventServiceAttributes,
        fleet_profile_value_attributes: {
          po_number: values.po,
        },
        ...(values.isDriverReporting
          ? {
              reported_user_email: values.driverEmail,
              reported_user_phone: values.driverPhone,
              reported_user_name: values.driverName,
            }
          : {
              reported_user_email: values.reporterEmail,
              reported_user_phone: values.reporterPhone,
              reported_user_name: values.reporterName,
            }),
        payment_account_no: values.paymentAccountNumber,
        payment_method_id:
          this.tenantName === DCLI_CC
            ? this.dcliPaymentDefaults
            : values.paymentMethod?.value, // Set Payment to House Account for DCLI webform
        emergency: values.emergency,
        scheduled_time: values.scheduledTime,
        needed_by: toUTC(values.neededBy),
        unit_available: toUTC(values.unitAvailable),
      },
      driver_notification: values.driverNotificationMethod.value || null,
      reporter_notification: values.reporterNotificationMethod.value,
      are_you_the_driver: values.isDriverReporting,
      additional_details: values.additionalDetails,
      fleet_details: {
        crosswalk_entry_id: values.crosswalkEntryId,
        fleet_no: values.fleetNo,
        name: values.newFleetName,
        address: values.newFleetAddress,
        city: values.newFleetCity,
        state: values.newFleetState,
        country: values.newFleetCountry,
        email_address: values.newFleetEmail,
        phone_number: values.newFleetPhone,
        scac_code: values.newFleetScacCode?.toUpperCase(),
        dot_number: values.newFleetDotNumber,
      },
    };
    if (this.tenantName === DCLI_CC) {
      web_service_request.incident["custom_fields"] = {
        breakdown_location: values.breakdown_location,
      };
    }
    const response = (
      await axios.post(
        `${this.apiEndpoint}online_form/create`,
        { web_service_request },
        {
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.authToken,
          },
        }
      )
    ).data;
    return response;
  }

  async fetchFleetInformation(customerAccountNo) {
    const response = await axios
      .post(
        `${this.apiEndpoint}online_form/fleet_identification`,
        { account_no: customerAccountNo },
        {
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.authToken,
          },
        }
      )
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }

  async fleetSearch(
    fleetName,
    fleetAddress,
    scacCode,
    dotNumber,
    email,
    phone
  ) {
    var fleetSearchAPI = `${this.apiEndpoint}online_form/fleet_search?`;

    const response = (
      await axios.get(fleetSearchAPI, {
        params: {
          fleet_name: fleetName,
          address: fleetAddress,
          scac_code: scacCode,
          dot_number: dotNumber,
          email_address: email,
          phone_number: phone.substr(phone.length - 10),
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
    return response.tenant.map((option) => ({
      td_ep_reference_no: null,
      id: option.id,
      name: option.name,
      iep_id: option.iep_id,
      truck_dispatch_id: option.truck_dispatch_id,
      scac_code: option.scac_code,
      addresses: [
        {
          address: option.addrs,
          city: option.city,
          state: option.state,
          country: option.country,
        },
      ],
      phone_number: option.phone_number,
      email_address: option.email_address,
    }));
  }

  async tireBrandOptions() {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/tire_brand_list`, {
        params: {},
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
    return response.tire_brands.map((option) => ({
      label: option,
      value: option,
    }));
  }

  async appointmentSlots(date) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/available_slots`, {
        params: date ? {date: date} : {},
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
    return response
  }

  async getNearestTenants(lat, lng) {
    const response = await axios.get(
      `${this.apiEndpoint}online_form/service_centers?lat=${lat}&long=${lng}`,
      {
        params: {},
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      }
    );
    return response.data.service_centers.map((option) => ({
      label: option.name,
      value: option.service_center_id,
      tenantInfo: option,
    }));
  }

  async tireSizeOptions() {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/tire_sizes`, {
        params: {},
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
    return response.tire_sizes.map((option) => ({
      label: option,
      value: option,
    }));
  }

  async shipToAccountNoSearch(fleetId, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/oem_accounts_search`, {
        params: { tenant_id: fleetId, search: search, page: page},
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data
    return({
      options: response.oem_agreements.map((oem) => (
        {
          label: oem.ship_to_address,
          value: oem.ship_to_account_no
        }
      )),
      hasMore: response.has_more
    })
  }
}

export default ReachAPI;
