import React, { useContext } from "react";
import Select from "../../Components/Select";
import TenantContext from "../../OnlineFormContext";
import AARCodes from "./AARCodes";

function Service({
  selected,
  system,
  subsystem,
  onChange,
  equipmentType,
  equipmentTypeId,
  hasError,
}) {
  const contextValues = useContext(TenantContext);
  const aarCodes = new AARCodes(contextValues.tenantAuthToken, equipmentType, equipmentTypeId);

  return (
    <div className="ui fluid input bs-field">
      <Select
        value={selected}
        asynchronous={true}
        paginate={true}
        loadOptions={async (search, _, { page }) => ({
          ...(await aarCodes.serviceOptions(system, subsystem, search, page)),
          additional: {
            page: page + 1,
          },
        })}
        onChange={({
          label,
          value,
          system,
          subsystem,
          tireCategory,
          aarPositionOptions,
          aarCauseOfFailureOptions,
          jobId,
          equipmentCategoryId,
        }) => {
          onChange({
            option: { label, value },
            system,
            subsystem,
            tireCategory,
            aarPositionOptions,
            aarCauseOfFailureOptions,
            jobId,
            equipmentCategoryId,
          });
        }}
        additional={{
          page: 1,
        }}
        cacheUniq={[selected]}
        placeholder={"Service"}
        hasError={hasError}
      />
    </div>
  );
}

export default Service;
