import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { PrimaryButton, SecondaryButton } from "./../Components/Button.js";
import Select from "../Components/Select";
import TenantContext from "./../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";
import * as Datetime from "react-datetime";
import moment from "moment-timezone";
import EventSchedule from "../EventSchedule";

function EventDetails({ formData, setFormData, nextStep, prevStep }) {
  const [direction, setDirection] = useState("back");
  const [showAccount, setAccount] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const contextValues = useContext(TenantContext);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);
  const validateEventDetail = (option) => {
    if (!option) return "Required.";
  };

  const renderInput = (props) => {
    return (
      <div>
        <input {...props} className={"bs-input"} placeholder=" " />
        <label className="bs-label">Needed By (optional)</label>
      </div>
    );
  };

  const renderUnitAvailableInput = (props) => {
    return (
      <div>
        <input {...props} className={"bs-input"} placeholder=" " />
        <label className="bs-label">Unit Available (optional)</label>
      </div>
    );
  };

  useEffect(() => {
    async function fetchPaymentMethodOptions() {
      let response = await reachAPI.paymentMethodOptions(formData.fleetId, contextValues.oem ? formData.fleetNo : null);
      setPaymentMethodOptions(response);
    }
    fetchPaymentMethodOptions();
  }, []);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ errors, touched, values, isValidating, setFieldValue }) => (
          <Form>
            <div className="form-section review_fleet">
              <div className="form-group custom-input">
                <h4 className="qus-title">Describe this event</h4>

                <div className="ui fluid input bs-field">
                  <div className="ui two column grid actions qus-toggle">
                    <div className="column t-left">Emergency</div>
                    <div className="column t-right">
                      <label className="switch">
                        <Field name="emergency">
                          {({ field, form, meta }) => {
                            return (
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={field.value}
                                  {...field}
                                />
                                <span className="slider round"></span>
                              </label>
                            );
                          }}
                        </Field>
                      </label>
                    </div>
                  </div>
                </div>

                <EventSchedule setFieldValue={ setFieldValue } formData={ formData } />

                <div className="ui fluid input bs-field datefield">
                  <Field
                    name="neededBy"
                    placeholder=" "
                    render={({ field, form }) => {
                      return (
                        <Datetime
                          isValidDate={(current) =>
                            current.isAfter(
                              Datetime.moment().subtract(1, "day")
                            )
                          }
                          renderInput={renderInput}
                          onChange={(val) => {
                            moment.isMoment(val)
                              ? form.setFieldValue(
                                  field.name,
                                  val.format("MM/DD/YYYY hh:mm A")
                                )
                              : form.setFieldValue(field.name, "");
                          }}
                          defaultValue={formData.neededBy}
                        />
                      );
                    }}
                  />
                </div>

                <div className="ui fluid input bs-field datefield">
                  <Field
                    name="unitAvailable"
                    placeholder=" "
                    render={({ field, form }) => {
                      return (
                        <Datetime
                          isValidDate={(current) =>
                            current.isAfter(
                              Datetime.moment().subtract(1, "day")
                            )
                          }
                          renderInput={renderUnitAvailableInput}
                          onChange={(val) => {
                            moment.isMoment(val)
                              ? form.setFieldValue(
                                  field.name,
                                  val.format("MM/DD/YYYY hh:mm A")
                                )
                              : form.setFieldValue(field.name, "");
                          }}
                          defaultValue={formData.unitAvailable}
                        />
                      );
                    }}
                  />
                </div>

                <div className="ui fluid input bs-field">
                  <Field
                    name="reference"
                    className="bs-input"
                    placeholder=" "
                    autoComplete="off"
                  />
                  <label className="bs-label">Reference # (optional)</label>
                </div>
                <div className="ui fluid input bs-field">
                  <Field
                    name="po"
                    className="bs-input"
                    placeholder=" "
                    autoComplete="off"
                  />
                  <label className="bs-label">PO # (optional)</label>
                </div>

                {contextValues.webServiceRequestConfig
                  .payment_method_enabled && (
                  <div className="ui fluid input bs-field">
                    <Field
                      name={"paymentMethod"}
                      className="bs-input"
                      placeholder=" "
                      validate={
                        direction === "back" ||
                        !contextValues.webServiceRequestConfig
                          .payment_method_required
                          ? null
                          : validateEventDetail
                      }
                    >
                      {({ field, form, meta }) => (
                        <Select
                          value={values.paymentMethod}
                          options={paymentMethodOptions}
                          onChange={(val) => {
                            form.setFieldValue(field.name, val);
                            form.setFieldValue("paymentAccountNumber", "");
                            if (
                              val.accountRequired &&
                              val.accountNumner !== null
                            )
                              form.setFieldValue(
                                "paymentAccountNumber",
                                val.accountNumner
                              );
                          }}
                          placeholder={
                            contextValues.webServiceRequestConfig
                              .payment_method_required
                              ? "Payment Method"
                              : "Payment Method (optional)"
                          }
                          hasError={meta.touched && meta.error}
                          hasGroupOption={true}
                          filterOption={(option, inputValue) => {
                            const { label, data } = option;
                            const query = new RegExp(inputValue, "i");

                            return (
                              label.match(query) || data.group.match(query)
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {((values.paymentMethod?.accountRequired &&
                  values.paymentMethod.accountNumner === null) ||
                  (values.paymentMethod?.accountRequired &&
                    formData.newFleet)) && (
                  <div className="ui fluid input bs-field">
                    <Field
                      name="paymentAccountNumber"
                      className="bs-input"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label className="bs-label">Account #</label>
                  </div>
                )}

                <div className="ui fluid input bs-field">
                  <Field
                    name="additionalDetails"
                    className="bs-input"
                    placeholder=" "
                    autoComplete="off"
                  />
                  <label className="bs-label">
                    Additional Details (optional)
                  </label>
                </div>
              </div>
            </div>
            <div className="footer-fixed">
              <div className="ui two column grid actions">
                <div className="column">
                  <SecondaryButton
                    text="Back"
                    type="submit"
                    onClick={() => setDirection("back")}
                  />
                </div>
                <div className="column">
                  <PrimaryButton
                    text="Next"
                    onClick={() => setDirection("forward")}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

EventDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default EventDetails;
