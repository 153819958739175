import { useContext } from "react";
import axios from "axios";
import TenantContext from "./../../OnlineFormContext";

class VMRSCodes {
  constructor(authToken, equipmentType, equipmentTypeId) {
    this.authToken = authToken;
    this.equipmentType = equipmentType;
    this.equipmentTypeId = equipmentTypeId;
    const contextValues = useContext(TenantContext);
    this.apiEndpoint = contextValues.apiEndpoint;
  }

  async systemOptions(search, page) {
    let results = await this.loadOptions("system", { search, page });

    return {
      options: results.vmrs_system.map((option) => ({
        label: option.system_description,
        value: option.code,
        isTireAsSystem: option.service_type_tire
      })),
      hasMore: results.has_more,
    };
  }

  async assemblyOptions(system, search, page) {
    let results = await this.loadOptions("assembly", { search, page, system });

    return {
      options: results.vmrs_system.map((option) => ({
        label: option.assembly_description,
        value: option.code,
        system: {
          label: option.system_description,
          value: option.system_code,
        },
        tireCategory: option.tire_equipment_category,
        equipmentCategoryId: option.category_id,
      })),
      hasMore: results.has_more,
    };
  }

  async componentOptions(system, assembly, search, page) {
    let results = await this.loadOptions("component", {
      search,
      page,
      system,
      assembly,
    });

    return {
      options: results.vmrs_system.map((option) => ({
        label: option.component_description,
        value: option.code,
        system: {
          label: option.system_description,
          value: option.system_code,
        },
        assembly: {
          label: option.assembly_description,
          value: option.assembly_code,
        },
        tireCategory: option.tire_equipment_category,
        equipmentCategoryId: option.category_id,
      })),
      hasMore: results.has_more,
    };
  }

  async loadOptions(codeLevel, opts) {
    return (
      await axios.get(`${this.apiEndpoint}online_form/vmrs_list`, {
        params: {
          level: codeLevel,
          equipment_type: this.equipmentType,
          equipment_type_id: this.equipmentTypeId,
          search: opts.search,
          page: opts.page,
          ...(codeLevel === "assembly" && { vmrs_system_code: opts.system }),
          ...(codeLevel === "component" && {
            vmrs_system_code: opts.system,
            vmrs_assembly_code: opts.assembly,
          }),
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;
  }

  async positionOptions(assembly, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/vmrs_positions`, {
        params: {
          vmrs_assembly_code: assembly,
          search: search,
          page: page,
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.positions.map((option) => ({
        label: option.details,
        value: option.id,
      })),
      hasMore: response.has_more || response.meta?.has_more,
    };
  }

  async causeOfFailureOptions(assembly, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/vmrs_reason_for_repair`, {
        params: {
          vmrs_assembly_code: assembly,
          search: search,
          page: page,
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.reason_for_repair.map((option) => ({
        label: option.details,
        value: option.id,
      })),
      hasMore: response.has_more,
    };
  }

  async workToBeAccomplishedOptions(assembly, search, page) {
    const response = (
      await axios.get(`${this.apiEndpoint}online_form/wa_codes`, {
        params: {
          vmrs_assembly_code: assembly,
          search: search,
          page: page,
        },
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": this.authToken,
        },
      })
    ).data;

    return {
      options: response.wa_codes.map((option) => ({
        label: option.details,
        value: option.id,
      })),
      hasMore: response.meta.has_more,
    };
  }
}

export default VMRSCodes;
