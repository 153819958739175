import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "./../Components/Header.js";
import * as CONSTANT from "../Config";

function GoogleReCaptcha({ verified }) {
  return (
    <div className="login-details" id="login-details">
      <Header />
      <div className="form-section">
        <div className="form-group">
          <h3 className="sm-title">Please confirm you’re not a robot</h3>
          <ReCAPTCHA
            sitekey={CONSTANT.GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={verified}
          />
        </div>
      </div>
    </div>
  );
}

export default GoogleReCaptcha;
