import React, { useContext, useEffect, useState } from "react";
import Select from "../Components/Select";
import TenantContext from "../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";

function NearestTenant({ form, hasError, onChange, placeholder }) {
  const contextValues = useContext(TenantContext);
  const [nearestTenants, setNearestTenants] = useState([]);

  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  useEffect(() => {
    if (form.values.reportedLat && form.values.reportedLon) {
      fetchNearestTenants();
    }
  }, [form.values.reportedLat, form.values.reportedLon]);

  async function fetchNearestTenants() {
    let response = await reachAPI.getNearestTenants(
      form.values.reportedLat,
      form.values.reportedLon
    );
    setNearestTenants(response);
  }
  const tenantNameAddressFormat = ({ value, label, tenantInfo }) => (
    <div className="select2-result-label" style={{ display: "block" }}>
      <span value={value}>
        {label}{" "}
        {tenantInfo.distance && typeof tenantInfo.distance === "number"
          ? "- " + tenantInfo.distance.toFixed(2) + " Miles"
          : ""}
        <br />
        <span style={{ fontSize: "9px", color: "grey", marginLeft: "10px" }}>
          <i className="fa fa-map-marker loc_indent"></i>
          <i>{tenantInfo.address}</i>
        </span>
      </span>
    </div>
  );
  return (
    <Select
      options={nearestTenants}
      onChange={(e) => {
        onChange(e);
      }}
      value={form.values.service_center_id}
      placeholder={placeholder}
      formatOptionLabel={tenantNameAddressFormat}
      hasError={hasError}
    />
  );
}

export default NearestTenant;
