import React from "react";
import TenantLogo from "./../Components/TenantLogo.js";
import HelplineMsg from "./../Components/HelplineMsg.js";
import PoweredByFooter from "./../Components/PoweredByFooter.js";

function UnsupportBrowser({ message, statusCode }) {
  return (
    <div className="scroll-more">
      <div className="ui stackable grid login-section">
        <div className="padlr-6">
          <TenantLogo />
          <div className="actions">
            <h3 className="sm-title red-header">Unsupported Browser</h3>
            <div className="ie-message">
              Unfortunately, this web form is not supported on your Internet
              Explorer browser. We recommend using Chrome, Firefox or Safari.
            </div>
            <HelplineMsg />
          </div>
        </div>
        <PoweredByFooter />
      </div>
    </div>
  );
}

export default UnsupportBrowser;
