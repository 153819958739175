import React, { useContext } from "react";
import TenantContext from "./../OnlineFormContext";

function TenantLogo(props) {
  const contextValues = useContext(TenantContext);
  return (
    <div className="sixteen wide column mobile hidden logo-header">
      <span>
        <img src={contextValues.tenantLogo} alt="Logo" />
      </span>
      <p>Emergency Road Service</p>
    </div>
  );
}

export default TenantLogo;
