import React, { useContext, useEffect, useState } from "react";
import Select from "../Components/Select";
import TenantContext from "./../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";

function TireBrand({ selected, onChange, placeholder }) {
  const contextValues = useContext(TenantContext);
  const [tireBrandOptions, setTireBrandOpions] = useState([]);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  useEffect(() => {
    async function fetchTireBrandOptions() {
      let response = await reachAPI.tireBrandOptions();
      setTireBrandOpions(response);
    }
    fetchTireBrandOptions();
  }, []);

  return (
    <Select
      value={selected}
      options={tireBrandOptions}
      onChange={(e) => {
        onChange(e);
      }}
      placeholder={placeholder}
    />
  );
}

export default TireBrand;
