import React, { useState } from "react";
import Select from "../Components/Select";
import App from "./../App";

function SelectEnvironment({ onChange }) {
  const [selectedEnv, setSelectedEnv] = useState(null);
  const envOptions = [
    { value: "http://lvh.me:3005/api/", label: "Development" },
    { value: "https://testing.reach24.net/api/", label: "Testing" },
    { value: "https://stage.reach24.net/api/", label: "Staging" },
    { value: "https://demo.reach24.net/api/", label: "Production Demo" },
  ];
  return (
    <div>
      <div
        className="ui grid middle aligned inverted"
        style={{ height: "100vh", margin: "0" }}
      >
        <div className="ui two column centered grid">
          <div className="column center aligned">
            <Select
              value={selectedEnv}
              options={envOptions}
              onChange={(val) => {
                setSelectedEnv(val.value);
                onChange(val.value);
              }}
              placeholder={"Select Environment"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectEnvironment;
