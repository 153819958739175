import React, { useContext } from "react";
import Select from "../../Components/Select";
import TenantContext from "../../OnlineFormContext";
import VMRSCodes from "./VMRSCodes";

function Component({
  selected,
  system,
  assembly,
  onChange,
  equipmentType,
  equipmentTypeId,
  hasError,
}) {
  const contextValues = useContext(TenantContext);
  const vmrsCodes = new VMRSCodes(contextValues.tenantAuthToken, equipmentType, equipmentTypeId);

  return (
    <div className="ui fluid input bs-field component-section">
      <Select
        asynchronous={true}
        paginate={true}
        value={selected}
        loadOptions={async (search, _, { page }) => ({
          ...(await vmrsCodes.componentOptions(system, assembly, search, page)),
          additional: {
            page: page + 1,
          },
        })}
        onChange={({ label, value, system, assembly, tireCategory, equipmentCategoryId }) => {
          onChange({
            option: { label, value },
            equipmentCategoryId,
            tireCategory,
            system,
            assembly,
          });
        }}
        additional={{
          page: 1,
        }}
        cacheUniq={[selected]}
        placeholder={"Component (optional)"}
      />
    </div>
  );
}

export default Component;
