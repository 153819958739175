import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PhoneInputField = ({ field, form, onFocus, onBlur }) => {
  let fieldName = field.name;
  return (
    <PhoneInput
      countries={["CA", "IN", "US"]}
      defaultCountry="US"
      name={field.name}
      value={field.value}
      className={form.touched[fieldName] && form.errors[fieldName] ? "error" : ""}
      onChange={(value) => {
        form.setFieldValue(field.name, value);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default PhoneInputField;
