import React from "react";
import moment from "moment-timezone";

function Slot({ time, selected }) {
  const formatted = (time) => (moment(time, 'YYY-MM-DDThh:mm').format('h:mm a'))

  return(
    <div
      className={"slot" + (selected ? ' selected-slot' : '') }
    >
      { formatted(time) }
    </div>
  )
}

export default Slot