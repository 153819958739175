import React, { useContext } from "react";
import TenantContext from "./../OnlineFormContext";

function HelplineMsg(props) {
  const contextValues = useContext(TenantContext);

  return (
    <div>
      <p className="notifimsg">
        {contextValues.webServiceRequestConfig.help_message}
        &nbsp;For support please contact
        <a href={"tel:" + contextValues.webServiceRequestConfig.contact_number}>
          {contextValues.webServiceRequestConfig.contact_number}
        </a>
      </p>
    </div>
  );
}

export default HelplineMsg;
