import React, { useState, useEffect } from "react";
import App from "./App";
import SelectEnvironment from "./SelectEnvironment/SelectEnvironment";

function EnvironmentSelection({}) {
  const [endpoint, setEndpoint] = useState();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production")
      setEndpoint(process.env.REACT_APP_REACH_API);
  }, []);

  if (endpoint) {
    return <App apiEndpoint={endpoint} />;
  } else {
    return <SelectEnvironment onChange={(val) => setEndpoint(val)} />;
  }
}

export default EnvironmentSelection;
