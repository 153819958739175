import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import MaskedInput from "react-text-mask";
import AddressAutoComplete from "../Components/AddressAutoComplete.js";
import PhoneField from "../Components/PhoneField";
import TenantContext from "../OnlineFormContext.js";
import { DCLI_CC } from "../utils/WebFormDefs.js";
import { PrimaryButton, SecondaryButton } from "./../Components/Button.js";

function EnterFleetInfo({ formData, setFormData, nextStep, startOver }) {
  const [direction, setDirection] = useState("back");
  const scacMask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];
  const dotMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const contextValues = useContext(TenantContext);
  const required = (option) => {
    if (!option) return "Required.";
  };
  const validateSCACCode = (option) => {
    if (!option) {
      if (contextValues.tenantName === DCLI_CC) {
        return "Required.";
      } else {
        return false;
      }
    } else if (option.replace(/_/g, "").length < 4) {
      return "Invalid scac code";
    }
  };
  const validateDot = (option) => {
    if (!option) return false;
    else if (option.replace(/_/g, "").length < 6) {
      return "Invalid dot number";
    }
  };
  const validateEmail = (option) => {
    if (!option) return false;
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(option)) {
      return "Invalid email address";
    }
  };
  const validatePhone = (option) => {
    if (!option) {
      return "Required.";
    } else return !isValidPhoneNumber(option);
  };

  return (
    <div>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          setFormData(values);
          nextStep();
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className="form-section review_fleet">
              <div className="form-group">
                <h4 className="qus-title">Enter your fleet information</h4>
                <div className="ui fluid input bs-field">
                  <Field
                    name="newFleetName"
                    className={
                      touched.newFleetName && errors.newFleetName
                        ? "bs-input error"
                        : "bs-input"
                    }
                    placeholder=" "
                    autoComplete="off"
                    validate={required}
                  />
                  <label className="bs-label">Fleet Name</label>
                </div>
                <div className="ui fluid input bs-field">
                  <Field
                    name="newFleetAddress"
                    className="bs-input"
                    placeholder=" "
                    validate={() => {
                      if (!values.newFleetAddress) {
                        return "Required.";
                      }
                    }}
                  >
                    {({ field, form, meta }) => (
                      <AddressAutoComplete
                        field={field}
                        form={form}
                        touched={touched}
                        errors={errors}
                        addressToShow={
                          values.newFleetAddress ? values.newFleetAddress : ""
                        }
                        onChange={(address, lat, lon, city, state, country) => {
                          form.setFieldValue("newFleetLat", lat);
                          form.setFieldValue("newFleetLon", lon);
                        }}
                        showUseCurrentLoc={false}
                        placeholderText={"Fleet Address"}
                        onError={(status) => {
                          form.setFieldValue("newFleetLat", "");
                          form.setFieldValue("newFleetLon", "");
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className="ui fluid input bs-field">
                  <Field
                    name="newFleetScacCode"
                    validate={
                      direction === "back" &&
                      contextValues.tenantName !== DCLI_CC
                        ? null
                        : validateSCACCode
                    }
                    className={
                      errors.newFleetScacCode ? "bs-input error" : "bs-input"
                    }
                  >
                    {({ field, form, meta }) => (
                      <MaskedInput
                        {...field}
                        mask={scacMask}
                        placeholder=" "
                        type="text"
                        className={
                          touched.newFleetScacCode && errors.newFleetScacCode
                            ? "bs-input error"
                            : "bs-input"
                        }
                        autoComplete="off"
                      />
                    )}
                  </Field>
                  <label className="bs-label">
                    SCAC Code{" "}
                    {contextValues.tenantName === DCLI_CC ? "" : "(optional)"}
                  </label>
                </div>
                <div className="ui fluid input bs-field">
                  <Field name="newFleetDotNumber" validate={validateDot}>
                    {({ field, form, meta }) => (
                      <MaskedInput
                        {...field}
                        mask={dotMask}
                        placeholder=" "
                        type="text"
                        className={
                          errors.newFleetDotNumber
                            ? "bs-input error"
                            : "bs-input"
                        }
                        autoComplete="off"
                      />
                    )}
                  </Field>
                  <label className="bs-label">DOT # (optional)</label>
                </div>
                <div className="ui fluid input bs-field">
                  <Field
                    name="newFleetEmail"
                    className={
                      errors.newFleetEmail ? "bs-input error" : "bs-input"
                    }
                    placeholder=" "
                    autoComplete="off"
                    validate={validateEmail}
                  />
                  <label className="bs-label">Email (optional)</label>
                </div>
                <PhoneField
                  phoneNumber={values.newFleetPhone}
                  validate={validatePhone}
                  name={"newFleetPhone"}
                  placeholderText={"Phone"}
                />
              </div>
            </div>
            <div className="footer-fixed">
              <div className="ui two column grid actions">
                <div className="column">
                  <SecondaryButton
                    text="Start Over"
                    type="submit"
                    onClick={startOver}
                  />
                </div>
                <div className="column">
                  <PrimaryButton
                    text="Next"
                    onClick={() => setDirection("forward")}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EnterFleetInfo;
