import React from "react";
import HelplineMsg from "./../Components/HelplineMsg.js";
import PoweredByFooter from "./../Components/PoweredByFooter.js";
import TenantLogo from "./../Components/TenantLogo.js";
import { PrimaryButton } from "./../Components/Button.js";

function Success({ startOver }) {
  return (
    <div className="ui stackable grid login-section login-end">
      <TenantLogo />
      <div className="status-info">
        <h3>Help is on the way</h3>
        <p>Let’s get you moving again</p>
      </div>
      <div className="parent-footer">
        <div className="footer-fixed secondary-footer">
          <div className="actions">
            <p className="btn-description">
              <i className="check circle icon"></i>
              <span>Your request has been submitted</span>
            </p>
            <PrimaryButton text="Done" onClick={startOver} type="button" />
            <HelplineMsg />
          </div>
        </div>
        <PoweredByFooter />
      </div>
    </div>
  );
}

export default Success;
