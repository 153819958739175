import React, { useContext, useEffect, useState } from "react";
import Select from "../Components/Select";
import TenantContext from "./../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";
import { DCLI_CC } from "../utils/WebFormDefs";

function TireSize({ selected, onChange, placeholder, hasError }) {
  const contextValues = useContext(TenantContext);
  const [tireSizeOptions, setTireSizeOptions] = useState([]);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  useEffect(() => {
    async function fetchTireSizeOptions() {
      let response = await reachAPI.tireSizeOptions();
      setTireSizeOptions(response);
    }
    fetchTireSizeOptions();
  }, []);

  return (
    <Select
      value={selected}
      options={tireSizeOptions}
      onChange={(e) => {
        onChange(e);
      }}
      placeholder={placeholder}
      hasError={hasError}
      hasCreatable={contextValues.tenantName !== DCLI_CC}
    />
  );
}

export default TireSize;
