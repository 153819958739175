import React, { useContext, useEffect, useState } from "react";
import EnterFleetNo from "../EnterFleetNo";
import EventForm from "../EventForm";
import GoogleReCaptcha from "../GoogleReCaptcha";
import SearchFleets from "../SearchFleets";
import UnsupportBrowser from "../UnsupportBrowser";
import Banner from "./../Components/Banner.js";
import MobileTenantLogo from "./../Components/MobileTenantLogo.js";
import PoweredByFooter from "./../Components/PoweredByFooter.js";
import TenantLogo from "./../Components/TenantLogo.js";
import LandingOptions from "./LandingOptions";

function Home(props) {
  const [currentState, setCurrentState] = useState("index");
  const [fleetDetails, setFleetDetails] = useState(null);
  const isIE = false || !!document.documentMode;

  useEffect(() => {
    if (fleetDetails) {
      setCurrentState("collectEventDetails");
    }
  }, [fleetDetails]);

  const startOver = () => {
    setFleetDetails(null);
    setCurrentState("index");
    window.scrollTo({ top: 0 });
  };

  const currentView = () => {
    switch (currentState) {
      case "index":
        return (
          <div className="ui stackable grid login-section">
            <div className="padlr-6">
              <TenantLogo />
              <LandingOptions
                setCurrentState={setCurrentState}
                startOver={startOver}
                setFleetDetails={setFleetDetails}
              />
            </div>
            <PoweredByFooter />
          </div>
        );
      case "captcha":
        return (
          <GoogleReCaptcha verified={() => setCurrentState("fleetSelection")} />
        );
      case "fleetSelection":
        return (
          <EnterFleetNo
            startOver={startOver}
            fleetSelected={(details) => setFleetDetails(details)}
            customerAccountUnknown={(details) =>
              setCurrentState("searchFleets")
            }
          />
        );
      case "collectEventDetails":
        return (
          <EventForm
            fleetInfo={fleetDetails.fleetInfo}
            fleetNo={fleetDetails.fleetNo}
            startOver={startOver}
          />
        );
      case "searchFleets":
        return (
          <SearchFleets
            fleetSelected={(details) => setFleetDetails(details)}
            customerAccountKnown={() => setCurrentState("captcha")}
            startOver={startOver}
          />
        );

      default:
      // code block
    }
  };

  return (
    <div className="online-request">
      <div className="ui grid stackable request-service">
        <div
          className={
            currentState === "index"
              ? "eight wide tablet nine wide computer column"
              : "mobile hidden eight wide tablet nine wide computer column"
          }
        >
          <MobileTenantLogo />
          <Banner />
        </div>
        <div className="eight wide tablet seven wide computer column">
          {isIE ? (
            <UnsupportBrowser />
          ) : (
            <div className="scroll-more">{currentView()}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
