import React, { useState, useContext, useEffect } from "react";
import TenantContext from "./../OnlineFormContext";
import ScheduleSelector from "./ScheduleSelector"

function EventSchedule({ setFieldValue, formData }) {
  const contextValues = useContext(TenantContext);
  if(contextValues.acceptsAppointment)
    return(
      <ScheduleSelector setFieldValue={ setFieldValue } formData={ formData } />
    )
  else
    return(<></>)
}
export default EventSchedule;
