import React, { useState, useContext } from "react";
import {
  PrimaryButton,
  BorderInheritButton,
  MiniButton,
} from "../Components/Button.js";
import Input from "./../Components/Input.js";
import TenantContext from "./../OnlineFormContext";

function FleetNotFound({ newFleet, startOver, fleetSelected, modifySearch }) {
  const [selectedFleet, setSelectedFleet] = useState(null);
  const contextValues = useContext(TenantContext);
  return (
    <div>
      <div className="form-section">
        <div className="form-group">
          <div className="ui fluid input bs-field">
            <div className="ui two column grid actions qus-toggle">
              <div className="column t-left">
                <h4 className="md-title">Your fleet was not found</h4>
              </div>
              <div className="column t-right">
                <MiniButton
                  text="Modify Search"
                  type="button"
                  onClick={modifySearch}
                />
              </div>
            </div>
          </div>

          {newFleet.fleetName && (
            <Input
              type="text"
              value={newFleet.fleetName}
              readOnly="readOnly"
              label_name="Fleet Name"
              required="required"
            />
          )}
          {newFleet.fleetAddress && (
            <Input
              type="text"
              value={newFleet.fleetAddress}
              readOnly="readOnly"
              label_name="Fleet Address"
              required="required"
            />
          )}
        </div>
      </div>

      <div className="footer-fixed">
        <div className="actions">
          {contextValues.webServiceRequestConfig.enable_new_customer &&
          <PrimaryButton
            text="Request Service for This New Fleet"
            type="button"
            onClick={() =>
              fleetSelected({
                fleetInfo: {
                  name: newFleet.fleetName,
                  addresses: [
                    {
                      address: newFleet.fleetAddress,
                      city: "",
                      state: "",
                      country: "",
                    },
                  ],
                  newFleet: true,
                  newFleetAddress: newFleet.fleetAddress,
                  newFleetScacCode: newFleet.scacCode,
                  newFleetDotNumber: newFleet.dot,
                  newFleetEmail: newFleet.email,
                  newFleetPhone: newFleet.phone,
                  newFleetCity: newFleet.fleetCity,
                  newFleetState: newFleet.fleetState,
                  newFleetCountry: newFleet.fleetCountry,
                },
                fleetNo: null,
              })
            }
          />}
          <BorderInheritButton
            text="Start Over"
            type="button"
            onClick={startOver}
          />
        </div>
      </div>
    </div>
  );
}

export default FleetNotFound;
