import React, { useContext, useEffect } from "react";
import {
  PrimaryButton,
  BorderInheritButton,
  MiniButton,
} from "../Components/Button.js";
import TenantContext from "./../OnlineFormContext";

function reviewFleetDetails(selectedFleet, onFleetSelected) {
  window.scrollTo({top: 0});
  onFleetSelected({
    fleetInfo: selectedFleet,
    fleetNo: null,
  });
}

function FleetSearchResults({
  customerAccountKnown,
  fleetSelected,
  fleetsArray,
  modifySearch,
  newFleet,
}) {
  const contextValues = useContext(TenantContext);

  useEffect(() => {
    window.scrollTo({top: 0});
  }, []);

  const handleNewFleet = () => {
    window.scrollTo({top: 0});
    fleetSelected({
      fleetInfo: {
        name: newFleet.fleetName,
        addresses: [
          {
            address: newFleet.fleetAddress,
            city: "",
            state: "",
            country: "",
          },
        ],
        newFleet: true,
        newFleetAddress: newFleet.fleetAddress,
        newFleetScacCode: newFleet.scacCode,
        newFleetDotNumber: newFleet.dot,
        newFleetEmail: newFleet.email,
        newFleetPhone: newFleet.phone,
      },
      fleetNo: null,
    });
  };

  return (
    <div>
      <div className="form-section">
        <div className="form-group">
          <div className="ui fluid input bs-field">
            <div className="ui two column grid actions qus-toggle">
              <div className="column t-left">
                <h4 className="md-title">Select a fleet </h4>
              </div>
              <div className="column t-right">
                <MiniButton
                  text="Modify Search"
                  type="button"
                  onClick={modifySearch}
                />
              </div>
            </div>
          </div>

          <p className="label-title">{fleetsArray.length} fleets were found</p>
          {fleetsArray.map((fleet, index) => (
            <div className="addres-area" key={index}>
              <h4>{fleet.name}</h4>
              <p>
                {fleet.addresses[0].address}
                {fleet.scac_code && <span>SCAC Code: {fleet.scac_code}</span>}
                {fleet.phone_number && <span>Phone: {fleet.phone_number}</span>}
                {fleet.email_address && (
                  <span>Email: {fleet.email_address}</span>
                )}
              </p>
              <PrimaryButton
                text="Select"
                type="button"
                onClick={() => reviewFleetDetails(fleet, fleetSelected)}
              />
              {/* <div className="ui fluid input bs-field"></div> */}
            </div>
          ))}
        </div>
      </div>

      <div className="footer-fixed">
        <div className="actions">
          {
            contextValues.webServiceRequestConfig.enable_new_customer &&
            <PrimaryButton
              text="Your fleet was not found? 
                Request Service For This New Fleet"
              type="button"
              onClick={() => handleNewFleet()}
            />
          }
          {
            contextValues.webServiceRequestConfig
            .customer_account_lookup_enabled && (
            <BorderInheritButton
              text="I know my Customer Account #"
              type="button"
              onClick={customerAccountKnown}
            />
          )}
          {
            contextValues.webServiceRequestConfig.fleet_lookup_enabled &&
            !(  contextValues.webServiceRequestConfig.customer_account_lookup_enabled ||
                contextValues.webServiceRequestConfig.enable_new_customer
            ) && (
            <BorderInheritButton
              text="Your fleet was not found?"
              type="button"
              onClick={modifySearch}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FleetSearchResults;
