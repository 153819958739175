import React, { useState, useContext, useEffect } from "react";
import TenantContext from "./../OnlineFormContext";
import { Field } from "formik";
import Datetime from "react-datetime";
import ReachAPI from "../Shared/ReachAPI";
import moment from "moment-timezone";
import Slot from "./Slot";

function ScheduleSelector({ setFieldValue, formData }) {
  const contextValues = useContext(TenantContext);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [slots, setSlots] = useState([]);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);

  const setScheduledTime = (val) => {
    setFieldValue("scheduledTime", val);
    setSelectedSchedule(val);
  };

  const loadTimeSlots = async (date) => {
    setLoading(true);
    let response = await reachAPI.appointmentSlots(date.tz(contextValues.timezone).format());
    setSlots(response.slots);
    setDate(moment(response.date).format("MMMM DD"));
    setLoading(false);
  };

  useEffect(() => {
    loadTimeSlots(formData.scheduledDate || moment());
    setSelectedSchedule(formData.scheduledTime);
  }, []);

  const selectDisselect = (selected, time) => {
    if (selected) setScheduledTime("");
    else setScheduledTime(time);
  };

  return (
    <div className="schedule-selector">
      <h5 className="qus-title">Schedule Appointment</h5>
      <div className="schedule-selector-sub-header">Select Date & Time</div>
      <Field name="scheduledTime" type="text_field" />
      <div className="schedule-day-picker">
        <Field
          name="scheduledDate"
          render={({ field, form }) => {
            return (
              <Datetime
                open
                displayTimeZone={contextValues.timezone}
                input={false}
                timeFormat={false}
                renderInput={(props) => {
                  return <input {...props} />;
                }}
                onChange={(val) => {
                  loadTimeSlots(val);
                  form.setFieldValue(field.name, val);
                  setScheduledTime("");
                }}
                defaultValue={formData.scheduledDate || moment()}
              />
            );
          }}
        />
        <div className="timezone">{contextValues.timezone}</div>
      </div>
      <div className="schedule-time-picker">
        <div className="schedule-date">{date}</div>
        <div className="slot-selectors">
          {slots.length ? (
            ""
          ) : (
            <div className="slots-message">No Slots available</div>
          )}
          {loading ? (
            <div className="slots-message">Loading...</div>
          ) : (
            slots.map((slot) => (
              <span
                className="slot-container"
                onClick={() => {
                  selectDisselect(
                    slot.start_time == selectedSchedule,
                    slot.start_time
                  );
                }}
                key={slot.start_time}
              >
                <Slot
                  time={slot.start_time}
                  selected={slot.start_time == selectedSchedule}
                />
              </span>
            ))
          )}
        </div>
      </div>
      <div className="clear-both" />
    </div>
  );
}

export default ScheduleSelector;