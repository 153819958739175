import React from "react";
import { Progress } from "semantic-ui-react";

function SubHeader(props) {
  return (
    <div>
      <Progress percent={props.progress} size="tiny" color="blue"></Progress>
      <div className="ui two column grid actions secondary-header">
        <div className="column t-left">
          <span>{props.step}</span>
        </div>
        <div className="column t-right">
          <h4>{props.title}</h4>
          <i>{props.subtitle}</i>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
