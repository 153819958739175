import React, { useContext } from "react";
import TenantContext from "./../OnlineFormContext";

function MobileTenantLogo(props) {
  const contextValues = useContext(TenantContext);
  return (
    <div className="column mobile only">
      <div className="logo-header">
        <span>
          <img src={contextValues.tenantLogo} alt="Logo" />
        </span>
        <p>Emergency Road Service</p>
      </div>
    </div>
  );
}

export default MobileTenantLogo;
