import { Field, Formik } from "formik";
import React, { useContext, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import MaskedInput from "react-text-mask";
import AddressAutoComplete from "../Components/AddressAutoComplete.js";
import PhoneField from "../Components/PhoneField";
import FleetNotFound from "../SearchFleets/FleetNotFound.js";
import FleetSearchResults from "../SearchFleets/FleetSearchResults.js";
import ReachAPI from "../Shared/ReachAPI";
import { BorderInheritButton, PrimaryButton } from "./../Components/Button.js";
import Header from "./../Components/Header.js";
import TenantContext from "./../OnlineFormContext";

function SearchFleets({ fleetSelected, customerAccountKnown, startOver }) {
  const contextValues = useContext(TenantContext);
  const [fleetsList, setFleetsList] = useState(null);
  const [fleetNotFound, setFleetNotFound] = useState(false);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);

  const everythingBlank = (values) => {
    return (
      values.fleetName === "" &&
      values.scacCode === "" &&
      values.dot === "" &&
      values.email === "" &&
      values.phone === ""
    );
  };

  const required = (option) => {
    if (!option) return "Required.";
  };

  const validateSCACCode = (option) => {
    if (!option) return false;
    else if (option.replace(/_/g, "").length < 4) {
      return "Invalid scac code";
    }
  };

  const validateDot = (option) => {
    if (!option) return false;
    else if (option.replace(/_/g, "").length < 6) {
      return "Invalid dot number";
    }
  };

  const validateEmail = (option) => {
    if (!option) return false;
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(option)) {
      return "Invalid email address";
    }
  };

  const validatePhone = (option) => {
    if (!option) return false;
    else return !isValidPhoneNumber(option);
  };
  const scacMask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];
  const dotMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  return (
    <Formik
      initialValues={{
        fleetName: "",
        fleetAddress: "",
        fleetCity: "",
        fleetState: "",
        fleetCountry: "",
        scacCode: "",
        dot: "",
        email: "",
        phone: "",
      }}
      onSubmit={async (values) => {
        let result = await reachAPI.fleetSearch(
          values.fleetName,
          values.fleetAddress,
          values.scacCode.replace(/_/g, ""),
          values.dot.replace(/_/g, ""),
          values.email,
          values.phone
        );
        if (result.length === 0) {
          setFleetNotFound(true);
        } else if (result.length === 1) {
          fleetSelected({
            fleetInfo: result[0],
            fleetNo: result[0].td_ep_reference_no,
          });
        } else if (result.length > 1) {
          setFleetsList(result);
        }
      }}
    >
      {(props) => {
        const { values, errors, handleSubmit, setFieldValue } = props;
        return (
          <div className="search-fleet contnt-scroll" id="search-fleet">
            <form onSubmit={handleSubmit}>
              <Header />
              {!fleetsList && !fleetNotFound && (
                <div>
                  <div className="form-section group-list">
                    <div className="form-group">
                      <h3 className="md-title">Find your Customer Account #</h3>
                      <p className="label-title">
                        1. Please provide <b>any one</b> of the following
                        <span>*</span>
                      </p>
                      <div className="ui grid fleet-grid">
                        <div className="ui fluid input bs-field sixteen wide column">
                          <Field
                            name="fleetName"
                            className={
                              errors.fleetName ? "bs-input error" : "bs-input"
                            }
                            placeholder=" "
                            autoComplete="off"
                            validate={everythingBlank(values) ? required : ""}
                          />
                          <label className="bs-label">Fleet Name</label>
                        </div>
                        <div className="ui fluid input bs-field sixteen wide eight wide tablet eight wide computer column">
                          <Field
                            name="scacCode"
                            validate={
                              everythingBlank(values)
                                ? required
                                : validateSCACCode
                            }
                          >
                            {({ field, form, meta }) => (
                              <MaskedInput
                                {...field}
                                mask={scacMask}
                                placeholder=" "
                                type="text"
                                className={
                                  errors.scacCode
                                    ? "bs-input error"
                                    : "bs-input"
                                }
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <label className="bs-label">SCAC Code</label>
                        </div>
                        <div className="ui fluid input sixteen wide eight wide tablet eight wide computer column">
                          <div className="ui fluid input bs-field">
                            <Field
                              name="dot"
                              validate={
                                everythingBlank(values) ? required : validateDot
                              }
                            >
                              {({ field, form, meta }) => (
                                <MaskedInput
                                  {...field}
                                  mask={dotMask}
                                  placeholder=" "
                                  type="text"
                                  className={
                                    errors.dot ? "bs-input error" : "bs-input"
                                  }
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <label className="bs-label">DOT #</label>
                          </div>
                        </div>
                        <div className="ui fluid input bs-field sixteen wide eight wide tablet eight wide computer column">
                          <Field
                            name="email"
                            className={
                              errors.email ? "bs-input error" : "bs-input"
                            }
                            placeholder=" "
                            autoComplete="off"
                            validate={
                              everythingBlank(values) ? required : validateEmail
                            }
                          />
                          <label className="bs-label">Email</label>
                        </div>
                        <div className="ui fluid input sixteen wide eight wide tablet eight wide computer column">
                          <PhoneField
                            phoneNumber={values.phone}
                            validate={
                              everythingBlank(values) ? required : validatePhone
                            }
                            name={"phone"}
                            placeholderText={"Phone"}
                          />
                        </div>
                      </div>
                      <p className="label-title">
                        2. And the Address (optional)
                      </p>
                      <div className="ui fluid input bs-field">
                        <Field
                          name="fleetAddress"
                          className="bs-input"
                          placeholder=" "
                        >
                          {({ field, form, meta }) => (
                            <AddressAutoComplete
                              field={field}
                              form={form}
                              errors={errors}
                              addressToShow={
                                values.fleetAddress ? values.fleetAddress : ""
                              }
                              onChange={(
                                address,
                                lat,
                                lon,
                                city,
                                state,
                                country
                              ) => {
                                setFieldValue("fleetCity", city);
                                setFieldValue("fleetState", state);
                                setFieldValue("fleetCountry", country);
                              }}
                              showUseCurrentLoc={false}
                              placeholderText={"Fleet Address"}
                              onError={(status) => {}}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="footer-fixed">
                    <div className="actions">
                      <PrimaryButton text="Continue" type="submit" />
                      {contextValues.webServiceRequestConfig
                        .customer_account_lookup_enabled && (
                        <BorderInheritButton
                          text="I know my Customer Account #"
                          type="button"
                          onClick={customerAccountKnown}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {fleetsList && (
                <FleetSearchResults
                  customerAccountKnown={customerAccountKnown}
                  fleetSelected={fleetSelected}
                  fleetsArray={fleetsList}
                  modifySearch={() => {
                    setFleetsList(null);
                  }}
                  newFleet={values}
                />
              )}

              {fleetNotFound && (
                <FleetNotFound
                  newFleet={values}
                  startOver={startOver}
                  fleetSelected={fleetSelected}
                  modifySearch={() => {
                    setFleetNotFound(false);
                  }}
                />
              )}
            </form>
          </div>
        );
      }}
    </Formik>
  );
}

export default SearchFleets;
