import axios from "axios";
import React, { Component } from "react";
import "./App.css";
import MainLogo from "./assets/images/tenant_logo_placeholder.png";
import Home from "./Home";
import Loader from "./Loader";
import TenantContext from "./OnlineFormContext";
import ErrorTemplate from "./PermissionDenied/PermissionDenied.js";

class App extends Component {
  state = { loading: true, errorStatus: null };

  fetchOnlineRequestAccess() {
    const tenantName = window.location.hostname.split(".")[0];
    const haveOnlineFormAccess =
      this.props.apiEndpoint + "online_form/" + tenantName + "/check_access";
      
    axios
      .post(haveOnlineFormAccess, {})
      .then((res) => {
        let logo = res.data.tenant_logo || MainLogo;
        this.setState({
          onlineRequest: res.data.online_request_service,
          loading: false,
          authToken: res.data.auth_token,
          tenantLogo: logo,
          serviceCenterId: res.data.service_center_id,
          enabledEquipmentTypes: res.data.equipment_type,
          webServiceRequestConfig: res.data.web_service_request_config,
          acceptsAppointment: res.data.accepts_appointment,
          timezone: res.data.timezone,
          oem: res.data.oem,
        });
      })
      .catch((error) => {
        console.log(error.response.status);

        this.setState({
          onlineRequest: false,
          loading: false,
          errorStatus: error.response.status,
        });
      });
  }

  componentDidMount() {
    this.fetchOnlineRequestAccess();
  }


  render() {
    if (this.state.loading) return <Loader />;

    if (this.state.onlineRequest) {
      return (
        <TenantContext.Provider
          value={{
            tenantAuthToken: this.state.authToken,
            tenantLogo: this.state.tenantLogo,
            serviceCenterId: this.state.serviceCenterId,
            enabledEquipmentTypes: this.state.enabledEquipmentTypes,
            apiEndpoint: this.props.apiEndpoint,
            tenantName: this.state.webServiceRequestConfig.custom_configuration,
            webServiceRequestConfig: this.state.webServiceRequestConfig,
            acceptsAppointment: this.state.acceptsAppointment,
            timezone: this.state.timezone,
            oem: this.state.oem,
          }}
        >
          <Home />
        </TenantContext.Provider>
      );
    } else if (this.state.errorStatus === 401) {
      return (
        <ErrorTemplate
          message={"Sorry you are not authorized to access this page"}
          statusCode={this.state.errorStatus}
        />
      );
    } else {
      return (
        <ErrorTemplate
          message={"Sorry we can't find the page you are looking for."}
          statusCode={"Oops!"}
        />
      );
    }
  }
}

export default App;
