import React from "react";

export const PrimaryButton = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-primary"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};

export const PrimaryButtonDisabled = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-primary disabled"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const SecondaryButton = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-secondary"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const BorderInheritButton = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-secondary border-inherit"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const HiddenPrimaryButton = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-primary d-none"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const HiddenBorderInheritButton = (props) => {
  return (
    <button
      className="fluid ui button bs-btn bs-secondary border-inherit d-none"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const BorderInheritRightButton = (props) => {
  return (
    <button
      className="ui button bs-btn bs-secondary border-inherit t-right"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const TealButton = (props) => {
  return (
    <button
      className="fluid ui button teal"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};

export const MiniButton = (props) => {
  return (
    <button
      className="ui basic button mini"
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      {props.text}
    </button>
  );
};
