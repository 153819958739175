import React from "react";
import PhoneInputField from "./PhoneInputField";
import { Formik, Field } from "formik";

function PhoneField({ phoneNumber, validate, name, placeholderText }) {
  return (
    <div
      className={
        phoneNumber
          ? "ui fluid input bs-field Phone verified"
          : "ui fluid input bs-field"
      }
    >
      <Field
        type="tel"
        name={name}
        component={PhoneInputField}
        validate={validate}
        autoComplete="off"
      />
      <label className="bs-label">{placeholderText}</label>
    </div>
  );
}

export default PhoneField;
