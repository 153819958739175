import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PrimaryButton, SecondaryButton } from "./../Components/Button.js";
import Select from "../Components/Select";
import TenantContext from "./../OnlineFormContext";
import ReachAPI from "../Shared/ReachAPI";
import PhoneField from "../Components/PhoneField";

function DriverDetails({ formData, setFormData, prevStep, nextStep }) {
  const [direction, setDirection] = useState("back");
  const [serviceRequested, setServiceRequested] = useState(false);
  const contextValues = useContext(TenantContext);
  const reachAPI = new ReachAPI(contextValues.tenantAuthToken);
  const notificationOptions = [
    { value: "email", label: "Email" },
    { value: "sms", label: "Text Message" },
    { value: "email+sms", label: "Text Message & Email" },
    { value: "no-notification", label: "No Notification Needed" },
  ];
  const validate = (option) => {
    if (!option) return "Required.";
  };
  const validateEmail = (option) => {
    if (!option) {
      return "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(option)) {
      return "Invalid email address";
    }
  };
  const validatePhone = (option) => {
    if (!option) {
      return "Required";
    } else return !isValidPhoneNumber(option);
  };

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={async (values) => {
          setServiceRequested(true);
          setFormData(values);
          if (direction !== "back")
            await reachAPI.submitOnlineForm(
              values,
              contextValues.serviceCenterId
            );
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ errors, touched, values, isValidating, setFieldValue }) => (
          <Form>
            <div className="form-section review_fleet">
              <div className="form-group custom-input">
                <h4 className="qus-title">Enter the driver's information</h4>

                <div className="ui fluid input bs-field">
                  <div className="ui two column grid actions qus-toggle">
                    <div className="column t-left">
                      Is the driver with the unit?
                    </div>
                    <div className="column t-right">
                      <label className="switch">
                        <Field
                          name="driverWithUnit"
                          render={({ field, form }) => {
                            return (
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={field.value}
                                  onClick={(val) => {
                                    if (values.driverWithUnit) {
                                      setFieldValue("isDriverReporting", false);
                                      setFieldValue("driverName", "");
                                      setFieldValue("driverEmail", "");
                                      setFieldValue("driverPhone", "");
                                      setFieldValue(
                                        "driverNotificationMethod",
                                        ""
                                      );
                                    }
                                  }}
                                  {...field}
                                />
                                <span className="slider round"></span>
                              </label>
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {values.driverWithUnit && (
                  <div>
                    <div className="ui fluid input bs-field">
                      <Field
                        name="driverName"
                        className={
                          errors.driverName ? "bs-input error" : "bs-input"
                        }
                        placeholder=" "
                        autoComplete="off"
                        validate={direction === "back" ? null : validate}
                      />
                      <label className="bs-label">
                        Driver Name (first, last)
                      </label>
                    </div>

                    <PhoneField
                      phoneNumber={values.driverPhone}
                      validate={direction === "back" ? null : validatePhone}
                      name={"driverPhone"}
                      placeholderText={"Driver Phone #"}
                    />

                    <div className="ui fluid input bs-field">
                      <Field
                        name="driverEmail"
                        className={
                          errors.driverEmail ? "bs-input error" : "bs-input"
                        }
                        placeholder=" "
                        autoComplete="off"
                        validate={
                          direction !== "back" &&
                          (values.driverNotificationMethod.value === "email" ||
                            values.driverNotificationMethod.value ===
                              "email+sms")
                            ? validateEmail
                            : ""
                        }
                      />
                      <label className="bs-label">
                        Driver Email (optional)
                      </label>
                    </div>
                  </div>
                )}

                {values.driverWithUnit && (
                  <div>
                    <div className="ui fluid input bs-field">
                      <div className="ui two column grid actions qus-toggle">
                        <div className="column t-left">Are you the driver?</div>
                        <div className="column t-right ">
                          <Field
                            name="isDriverReporting"
                            render={({ field, form }) => {
                              return (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={field.value}
                                    {...field}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ui fluid input bs-field">
                      <Field
                        name="driverNotificationMethod"
                        className="bs-input"
                        placeholder=" "
                        validate={direction === "back" ? null : validate}
                      >
                        {({ field, form, meta }) => (
                          <Select
                            value={values.driverNotificationMethod}
                            options={notificationOptions}
                            onChange={(val) => {
                              form.setFieldValue(field.name, val);
                            }}
                            placeholder={"Driver Notification Method"}
                            hasError={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}

                {!values.isDriverReporting && (
                  <div>
                    <h4 className="qus-title">Who is reporting this issue?</h4>
                    <div className="ui fluid input bs-field">
                      <Field
                        name="reporterName"
                        className={
                          errors.reporterName ? "bs-input error" : "bs-input"
                        }
                        placeholder=" "
                        autoComplete="off"
                        validate={direction === "back" ? null : validate}
                      />
                      <label className="bs-label">
                        Reporter Name (first, last)
                      </label>
                    </div>

                    <PhoneField
                      phoneNumber={values.reporterPhone}
                      validate={direction === "back" ? null : validatePhone}
                      name={"reporterPhone"}
                      placeholderText={"Reporter Phone #"}
                    />

                    <div className="ui fluid input bs-field">
                      <Field
                        name="reporterEmail"
                        className={
                          errors.reporterEmail ? "bs-input error" : "bs-input"
                        }
                        placeholder=" "
                        autoComplete="off"
                        validate={
                          direction === "back" ||
                          values.reporterNotificationMethod.value ===
                            "no-notification"
                            ? null
                            : validateEmail
                        }
                      />
                      <label className="bs-label">Reporter Email</label>
                    </div>
                    <div className="ui fluid input bs-field">
                      <Field
                        name="reporterNotificationMethod"
                        className="bs-input"
                        placeholder=" "
                        validate={direction === "back" ? null : validate}
                      >
                        {({ field, form, meta }) => (
                          <Select
                            value={values.reporterNotificationMethod}
                            options={notificationOptions}
                            onChange={(val) => {
                              form.setFieldValue(field.name, val);
                            }}
                            placeholder={"Reporter Notification Method"}
                            hasError={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group"></div>
            </div>
            <div className="footer-fixed">
              <div className="ui two column grid actions">
                <div className="column">
                  <SecondaryButton
                    text="Back"
                    type="submit"
                    onClick={() => setDirection("back")}
                  />
                </div>
                <div className="column">
                  <PrimaryButton
                    text="Submit"
                    onClick={() => setDirection("forward")}
                    type="submit"
                    disabled={serviceRequested}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

DriverDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default DriverDetails;
