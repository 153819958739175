import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import CaptureAARService from "./AARServices/CaptureAARService";
import CaptureVMRSService from "./VMRSServices/CaptureVMRSService";
import DispositionOfTire from "./DispositionOfTire";
import TireBrand from "./TireBrand";
import TireSize from "./TireSize";
import TenantContext from "../OnlineFormContext.js";
import Position from "./Position";
import CauseOfFailure from "./CauseOfFailure";

import {
  PrimaryButton,
  SecondaryButton,
  BorderInheritRightButton,
} from "./../Components/Button.js";
import { DCLI_CC } from "../utils/WebFormDefs";

function ServiceLines({ formData, setFormData, nextStep, prevStep }) {
  const [direction, setDirection] = useState("back");
  const {
    equipmentType,
    equipmentTypeId,
    serviceLines,
    serviceCodingStandard,
  } = formData;

  const useVMRSCodes = serviceCodingStandard === "vmrs";
  const contextValues = useContext(TenantContext);
  const required = (option) => {
    if (!option) return "Required.";
  };
  let newServiceLine = {
    comments: "",
    causeOfFailure: null,
    type: useVMRSCodes ? "VMRS" : "AAR",
    aarService: {
      system: null,
      subsystem: null,
      service: null,
      jobId: null,
      equipmentCategoryId: null,
    },
    vmrsService: {
      system: null,
      assembly: null,
      component: null,
      equipmentCategoryId: null,
      workToBeAccomplished: "",
    },
    tireCategory: null,
    tireSize: "",
    position: null,
    oldTireTreadPattern: "",
    newTireTreadPattern: "",
    disPositionOfTire: null,
    aarPositionOptions: null,
    aarCauseOfFailureOptions: null,
    oldTireBrand: "",
    newTireBrand: "",
  };

  return (
    <Formik
      initialValues={{
        ...formData,
        serviceLines: serviceLines.length > 0 ? serviceLines : [newServiceLine],
      }}
      onSubmit={(values) => {
        setFormData(values);
        direction === "back" ? prevStep() : nextStep();
      }}
    >
      {({ errors, touched, values, isValidating }) => (
        <Form>
          <div className="form-section review_fleet">
            <div className="form-group custom-input">
              <FieldArray
                name="serviceLines"
                render={(arrayHelpers) => (
                  <div>
                    {values.serviceLines.length > 0 &&
                      values.serviceLines.map((serviceLine, index) => (
                        <div key={index}>
                          <div className="ui two column grid actions">
                            <div className="ui twelve wide column t-left">
                              <h4 className="qus-title">
                                What is the issue? (No.{index + 1})
                              </h4>
                            </div>
                            <div className="ui four wide middle aligned column">
                              <div className="actions cancel-issue t-right">
                                {index !== 0 && (
                                  <BorderInheritRightButton
                                    text="Remove"
                                    onClick={() => arrayHelpers.remove(index)}
                                    type="button"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          {useVMRSCodes ? (
                            <CaptureVMRSService
                              serviceLine={serviceLine}
                              equipmentType={equipmentType}
                              equipmentTypeId={equipmentTypeId}
                              fieldIndex={index}
                              errors={errors.serviceLines}
                              direction={direction}
                            />
                          ) : (
                            <CaptureAARService
                              serviceLine={serviceLine}
                              equipmentType={equipmentType}
                              equipmentTypeId={equipmentTypeId}
                              fieldIndex={index}
                              errors={errors.serviceLines}
                              direction={direction}
                            />
                          )}

                          {(serviceLine.vmrsService.assembly ||
                            serviceLine.aarService.service) && (
                            <div>
                              <div className="ui grid custmpadding">
                                <div className="sixteen wide tablet eight wide computer column">
                                  <div className="ui fluid input bs-field">
                                    {/* Required when selected service is #128 RepairAirLeak - Seal in DCLI*/}
                                    {/* Required when selected system is Tire in DCLI*/}
                                    <Field
                                      name={`serviceLines[${index}].position`}
                                      className="bs-input"
                                      placeholder=" "
                                      validate={
                                        direction !== "back" &&
                                        contextValues.tenantName === DCLI_CC &&
                                        (values.serviceLines[index]
                                          .isTireAsSystem ||
                                          values.serviceLines[index]
                                            .tireCategory ||
                                          (values.serviceLines[index]
                                            .aarService &&
                                            values.serviceLines[index]
                                              .aarService.system &&
                                            values.serviceLines[index]
                                              .aarService.service.value ===
                                              128))
                                          ? required
                                          : null
                                      }
                                    >
                                      {({ field, form, meta }) => (
                                        <Position
                                          selected={serviceLine.position}
                                          hasError={meta.touched && meta.error}
                                          placeholder={
                                            contextValues.tenantName ===
                                              DCLI_CC &&
                                            (values.serviceLines[index]
                                              .isTireAsSystem ||
                                              values.serviceLines[index]
                                                .tireCategory ||
                                              (values.serviceLines[index]
                                                .aarService &&
                                                values.serviceLines[index]
                                                  .aarService.system &&
                                                values.serviceLines[index]
                                                  .aarService.service.value ===
                                                  128))
                                              ? "Position"
                                              : "Position (optional)"
                                          }
                                          vmrsAssembly={
                                            serviceLine.vmrsService.assembly &&
                                            serviceLine.vmrsService.assembly
                                              .value
                                          }
                                          aarOptions={
                                            values.serviceLines[index]
                                              .aarPositionOptions
                                          }
                                          onChange={(val) => {
                                            form.setFieldValue(field.name, val);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                                <div className="sixteen wide tablet eight wide computer column">
                                  <div className="ui fluid input bs-field">
                                    <Field
                                      name={`serviceLines[${index}].causeOfFailure`}
                                      className="bs-input"
                                      placeholder=" "
                                    >
                                      {({ field, form, meta }) => (
                                        <CauseOfFailure
                                          selected={serviceLine.causeOfFailure}
                                          vmrsAssembly={
                                            serviceLine.vmrsService.assembly &&
                                            serviceLine.vmrsService.assembly
                                              .value
                                          }
                                          aarOptions={
                                            values.serviceLines[index]
                                              .aarCauseOfFailureOptions
                                          }
                                          onChange={(val) => {
                                            form.setFieldValue(field.name, val);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {(values.serviceLines[index].isTireAsSystem ||
                            values.serviceLines[index].tireCategory) && (
                            <div>
                              <div className="ui grid custmpadding">
                                <div className="sixteen wide tablet eight wide computer column">
                                  <div className="ui fluid input bs-field">
                                    {/* Required when selected system is Tire in DCLI*/}
                                    <Field
                                      name={`serviceLines[${index}].tireSize`}
                                      className="bs-input"
                                      placeholder=" "
                                      validate={
                                        direction !== "back" &&
                                        contextValues.tenantName === DCLI_CC &&
                                        (values.serviceLines[index]
                                          .isTireAsSystem ||
                                          values.serviceLines[index]
                                            .tireCategory)
                                          ? required
                                          : null
                                      }
                                    >
                                      {({ field, form, meta }) => (
                                        <TireSize
                                          selected={serviceLine.tireSize}
                                          onChange={(val) => {
                                            form.setFieldValue(field.name, val);
                                          }}
                                          placeholder={
                                            contextValues.tenantName ===
                                              DCLI_CC &&
                                            (values.serviceLines[index]
                                              .isTireAsSystem ||
                                              values.serviceLines[index]
                                                .tireCategory)
                                              ? "Tire Size"
                                              : "Tire Size (optional)"
                                          }
                                          hasError={meta.touched && meta.error}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                                <div className="sixteen wide tablet eight wide computer column">
                                  <div className="ui fluid input bs-field">
                                    <Field
                                      name={`serviceLines[${index}].disPositionOfTire`}
                                      className="bs-input"
                                      placeholder=" "
                                    >
                                      {({ field, form, meta }) => (
                                        <DispositionOfTire
                                          selected={
                                            serviceLine.disPositionOfTire
                                          }
                                          onChange={(val) => {
                                            form.setFieldValue(field.name, val);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              {contextValues.tenantName !== DCLI_CC && (
                                <div>
                                  <div className="ui grid custmpadding">
                                    <div className="sixteen wide tablet eight wide computer column">
                                      <div className="ui fluid input bs-field">
                                        <Field
                                          name={`serviceLines[${index}].oldTireTreadPattern`}
                                          className="bs-input"
                                          placeholder=" "
                                          autoComplete="off"
                                        />
                                        <label className="bs-label">
                                          Old Tire/Tread Pattern (optional)
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sixteen wide tablet eight wide computer column">
                                      <div className="ui fluid input bs-field">
                                        <Field
                                          name={`serviceLines[${index}].newTireTreadPattern`}
                                          className="bs-input"
                                          placeholder=" "
                                          autoComplete="off"
                                        />
                                        <label className="bs-label">
                                          New Tire/Tread Pattern (optional)
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ui grid custmpadding">
                                    <div className="sixteen wide tablet eight wide computer column">
                                      <div className="ui fluid input bs-field">
                                        <Field
                                          name={`serviceLines[${index}].oldTireBrand`}
                                          className="bs-input"
                                          placeholder=" "
                                        >
                                          {({ field, form, meta }) => (
                                            <TireBrand
                                              selected={
                                                serviceLine.oldTireBrand
                                              }
                                              onChange={(val) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  val
                                                );
                                              }}
                                              placeholder="Old Tire Brand (optional)"
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="sixteen wide tablet eight wide computer column">
                                      <div className="ui fluid input bs-field">
                                        <Field
                                          name={`serviceLines[${index}].newTireBrand`}
                                          className="bs-input"
                                          placeholder=" "
                                        >
                                          {({ field, form, meta }) => (
                                            <TireBrand
                                              selected={
                                                serviceLine.newTireBrand
                                              }
                                              onChange={(val) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  val
                                                );
                                              }}
                                              placeholder="New Tire Brand (optional)"
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="ui fluid input bs-field">
                            <Field
                              name={`serviceLines[${index}].comments`}
                              className="bs-input"
                              placeholder=" "
                              autoComplete="off"
                            />
                            <label className="bs-label">Comments</label>
                          </div>
                        </div>
                      ))}
                    <div className="ui two column grid actions">
                      <div className="column t-left"></div>
                      <div className="column">
                        <div className="actions cancel-issue t-right">
                          <BorderInheritRightButton
                            text="+ Add Another Issue"
                            onClick={() => arrayHelpers.push(newServiceLine)}
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="footer-fixed">
              <div className="ui two column grid actions">
                <div className="column">
                  <SecondaryButton
                    text="Back"
                    type="submit"
                    onClick={() => setDirection("back")}
                  />
                </div>
                <div className="column">
                  <PrimaryButton
                    text="Next"
                    onClick={() => setDirection("forward")}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

ServiceLines.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default ServiceLines;
