import React, { useContext } from "react";
import { default as ReactSelect, components } from "react-select";
import AsyncSelect from "react-select/async";
import TenantContext from "./../OnlineFormContext";

function DispositionOfTire({ selected, onChange }) {
  const contextValues = useContext(TenantContext);
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const fetchData = (inputValue, callback) => {
    const headers = {
      "Content-Type": "application/json",
      "Auth-Token": contextValues.tenantAuthToken,
    };
    var fetchDispositionOfTire =
      contextValues.apiEndpoint +
      "online_form/tire_dispositions?search=" +
      inputValue;
    fetch(fetchDispositionOfTire, { method: "GET", headers: headers })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        const tempArray = [];
        data.tire_dispositions.forEach((element) => {
          tempArray.push({ label: `${element}`, value: element });
        });
        callback(tempArray);
      })
      .catch((error) => {
        console.log(error, "Error on async select dropdown");
      });
  };

  return (
    <AsyncSelect
      debounceTimeout={300}
      value={selected}
      loadOptions={fetchData}
      onChange={(e) => {
        onChange(e);
      }}
      components={{
        IndicatorSeparator: () => null,
        ValueContainer: CustomValueContainer,
      }}
      placeholder={"Disposition of Off-Tire (optional)"}
      defaultOptions={true}
    />
  );
}

export default DispositionOfTire;
