import React from "react";

function PermissionDenied({ message, statusCode }) {
  return (
    <div
      className="ui grid middle aligned segments inverted"
      style={{ height: "100vh", margin: "0" }}
    >
      <div className="ui two column centered grid">
        <div className="column center aligned">
          <div className="ui message red inverted">
            <div className="value">{statusCode}</div>
            <div className="label">{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermissionDenied;
